  <div class="ai-summaries-container">

    <div class="overflow-mh50-flex" *ngIf="!fallbackInfoText; else noSummaries">
      <div *ngIf="aiAccountSummary.business_model?.length" class="insights-container">
        <h6 class><i class="far fa-solar-system"></i>Business Model</h6>
        <div class="insights-points-container">
          <span>{{aiAccountSummary.business_model}}</span>
        </div>
      </div>

      <div *ngIf="aiAccountSummary.strategic_goals?.length" class="insights-container">
        <h6 class><i class="far fa-bullseye-arrow"></i>Strategic Goals</h6>
        <div class="insights-points-container">
          <span *ngFor="let goal of aiAccountSummary.strategic_goals">{{goal.text}}<a
              [href]="goal | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a></span>
        </div>
      </div>

      <div *ngIf="aiAccountSummary.pains_challenges?.length" class="insights-container">
        <h6 class><i class="far fa-cloud-showers-heavy"></i>Challenges</h6>
        <div class="insights-points-container">
          <span *ngFor="let pain of aiAccountSummary.pains_challenges">{{pain.text}}
            <a [href]="pain | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a></span>
        </div>
      </div>

      <div *ngIf="aiAccountSummary.future_events?.length" class="insights-container">
        <h6 class><i class="far fa-calendar-star"></i>Future Events</h6>
        <div class="insights-points-container">
          <span *ngFor="let event of aiAccountSummary.future_events">{{event.text}}<a
              [href]="event | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a></span>
        </div>
      </div>

      @if (!pubMethods.isEmptyObjectOrArray(aiAccountSummary.bullets)){
        <div *ngFor="let category of aiAccountSummary.bullets | keyvalue"
          class="insights-container">
          <h6 class><i class="far fa-arrow-circle-right"></i>{{category.key}}</h6>
          <div class="insights-points-container">
            <span *ngFor="let bullet of category.value">
              {{bullet.text}}
              <a [href]="bullet | xbrlOrOcrAccountsLink: 'insights' : {companyId, totalNumberOfPages, accountsType, pdfURL, fileName}" target="_blank"><i class="fal fa-external-link fa-xs"></i></a>
            </span>            
          </div>
        </div>
      }
    </div>
  </div>


<ng-template #noSummaries>
  <p class="p-3 border bg-light mb-0 text-grey-600">{{fallbackInfoText}}
  </p>
</ng-template>
