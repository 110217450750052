import { Injectable } from '@angular/core';
import { MessageService } from './services/message.service';
import { CookieService } from 'ngx-cookie';
import { Observable, of } from 'rxjs';
import { apiUrl } from './api-url';
import * as Sentry from '@sentry/angular-ivy';
import { CompanyPageSectionEnums } from './classes/company';

export const baseUrl = apiUrl;
declare var $: any;

export enum EventSourceTypes {
  NewsSearchEventSourceType = 'NewsSearchEventSourceType',
  OfficerEventSourceType = 'OfficerEventSourceType',
  PSCEventSourceType = 'PSCEventSourceType',
  AccountsSearchEventSourceType = 'AccountsSearchEventSourceType',
  WebsiteSearchEventSourceType = 'WebsiteSearchEventSourceType',
  ImportExportEventSourceType = 'ImportExportEventSourceType',
  RelatedOfficerEventSourceType = 'RelatedOfficerEventSourceType',
  CorporateStructureEventSourceType = 'CorporateStructureEventSourceType',
  RelatedPSCEventSourceType = 'RelatedPSCEventSourceType',
  AccountsFilingEventSourceType = 'AccountsFilingEventSourceType',
  HiringEventSourceType = 'HiringEventSourceType',
}

export interface IGrowNotificationsParams {
  pageNumber?: number;
  eventSourceTypes?: string[];
  ruleSetIds?: number[];
  userIds?: string[];
  companyName?: string;
  notificationGroupId?: string;
  showReadNotifications?: boolean | null;
  orderBy?: string;
  orderDirection?: string;
}
@Injectable()
export class publicMethods {
  constructor(
    private messageService: MessageService,
    private cookieService: CookieService
  ) {}

  showInfoMessageWithoutAutoDisappear(message: string) {
    this.messageService.showInfo(message);
  }

  showWarningMessageWithoutAutoDisappear(message: string) {
    this.messageService.show(message);
  }

  showInfoMessage(message: string) {
    this.messageService.showInfo(message);
    setTimeout(() => this.messageService.hideInfo(), 5000);
  }

  log(message: string) {
    this.messageService.show(message);
    this.messageService.hide(10000);
  }

  handleError<T>(operation = 'operation', result?: T, loadingElements = null) {
    const loginUrl = '/login?message=';
    const redirectDelay = 1500;
    return (response: any): Observable<T> => {
      if (loadingElements) {
        Object.keys(loadingElements).forEach(function (key) {
          loadingElements[key] = false;
        });
      }
      try {
        console.log(response);
      } catch (err) {}
      try {
        console.log(response.error);
      } catch (err) {}

      let errorMessage = '';
      if (response.error && response.error.message) {
        errorMessage = response.error.message;
      }
      if (response.status === 401) {
        if (errorMessage === 'Unauthorized') {
          // prevent redirect from logout page to show webinars
          if (window.location.pathname !== '/logout') {
            setTimeout(function () {
              window.location.href = loginUrl + 'Please login to continue';
            }, redirectDelay);
          }
        } else if (
          errorMessage === 'You need to activate your account to do that'
        ) {
          setTimeout(function () {
            window.location.href = '/request_new_activation_token';
          }, redirectDelay);
        }
      }
      if (response.status === 403) {
        if (response?.error?.redirect_url) {
          this.messageService.show(`${errorMessage} Redirecting you now...`);
          setTimeout(function () {
            window.location.href = response.error.redirect_url;
          }, redirectDelay);
        } else {
          this.messageService.show(
            '403 Forbidden. ' +
              response.error +
              '.Please contact us for assistance.'
          );
          // Remove deprecated CSRF token, if it exists. The hostname will be set to null, which scopes it to the exact
          // subdomain (e.g. app.zint.io) but only the CSRF token for the apex domain (e.g. .zint.io) should be used.
          this.cookieService.remove('csrftoken');
          Sentry.captureMessage(
            `403 Forbidden: ${response.error.message || JSON.stringify(response.error)}`
          );
        }
      } else if (response.status === 413) {
        this.messageService.show(
          "The content you're trying to upload is too large."
        );
        this.messageService.hide(6000);
      } else if (response.error && response.error.message === undefined) {
        if (response.error.type !== 'abort') {
          this.messageService.show(
            'An unknown error occurred. Please clear your cookies or contact us for assistance.'
          );
        }
      } else {
        if (response.error) {
          this.log(errorMessage);
          if (
            errorMessage ===
            'Login successful. You have been logged out of another session elsewhere.'
          ) {
            setTimeout(function () {
              location.reload();
            }, redirectDelay);
          } else if (
            errorMessage ===
              'You have been logged out because your account was accessed from a different location.' ||
            errorMessage ===
              'We detected that you are logging in from a new location. For security, your session has expired. Please login again to continue.'
          ) {
            setTimeout(function () {
              window.location.href = loginUrl + response.error.message;
            }, redirectDelay);
          }
        }
      }
      return of(result as T);
    };
  }

  addJavascript() {
    $(document).ready(function () {
      var scripts = document.getElementsByClassName('customscript');
      for (var i = 0; i < scripts.length; i++) {
        var divElToModify = scripts[i];
        var newElement = document.createElement('script');
        newElement.type = 'text/javascript';
        newElement.src = divElToModify.getAttribute('src');
        newElement.async;
        divElToModify.parentNode.replaceChild(newElement, divElToModify);
      }
    });
  }

  stringifyDictForGetParams(dictObj: any) {
    return JSON.stringify(dictObj);
  }

  stringifyListForGetParams(arrayObj: Array<any>) {
    if (arrayObj && arrayObj.length > 0) {
      return JSON.stringify(arrayObj);
    } else {
      return '';
    }
  }

  stringifySetForGetParams(setObj: Set<any>) {
    const arrayObj = Array.from(setObj);
    return this.stringifyListForGetParams(arrayObj);
  }

  stringifyNumberForGetParam(numberObj: any) {
    if (numberObj === undefined) {
      return '';
    } else {
      return String(numberObj);
    }
  }

  allowDraggingOftable(tableIdentifier): void {
    $(function () {
      var pressed = false;
      var start = undefined;
      var startX, startWidth;
      $(tableIdentifier + ' th').mousedown(function (e) {
        start = $(this);
        pressed = true;
        startX = e.pageX;
        startWidth = $(this).width();
        $(start).addClass('resizing');
        $(start).addClass('no-select');
        // need to switch this namespace on and off again!
        $(document).on('mousemove.zintNamespace', function (e) {
          if (pressed) {
            var delta = e.pageX - startX;
            $(start).width(startWidth + delta);
          }
        });
      });
      $(document).mouseup(function () {
        if (pressed) {
          $(start).removeClass('resizing');
          $(start).removeClass('no-select');
          pressed = false;
          // need to switch this namespace off!
          $(document).off('mousemove.zintNamespace');
        }
      });
    });
  }

  getGetSearchStringFromParams(searchParams) {
    var getParams = '?';
    for (let key in searchParams) {
      if (getParams == '?') {
        getParams = getParams + key + '=' + searchParams[key];
      } else {
        getParams = getParams + '&' + key + '=' + searchParams[key];
      }
    }
    return getParams;
  }

  changeSearchParamsToDisplayFormat(searchParams) {
    var outputForParamsDisplay = [];
    for (let key in searchParams) {
      let value = searchParams[key];
      outputForParamsDisplay.push({ param: key, value: value });
    }
    return outputForParamsDisplay;
  }

  isSafari(): boolean {
    return (
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1
    );
  }

  isInternetExplorer(): boolean {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
    return false;
  }

  showIEWarningIfRequired(): void {
    if (this.isInternetExplorer()) {
      this.messageService.show(
        'Zint is optimised for Google Chrome. Please consider upgrading your browser to Chrome for a full experience.'
      );
    }
  }

  isEmptyObjectOrArray(value: Object | Array<any> | string): boolean {
    // TODO: replace the instances of this method with hasData method below
    if (value === null || value === undefined) return true;
    return (
      (value.constructor === Object && Object.keys(value).length === 0) ||
      (value.constructor === Array && value.length === 0) ||
      (value.constructor === String && value.length === 0)
    );
  }

  hasData(
    obj: Object | Array<any> | string | number | null,
    allowNull: boolean = false
  ): boolean {
    if (obj === null) return allowNull;
    if (typeof obj === 'number' && !isNaN(obj)) return true;
    if (typeof obj === 'boolean') return true;
    if (typeof obj === 'string') return obj.length > 0;

    if (Array.isArray(obj))
      return obj.length > 0 && obj.some(item => this.hasData(item, allowNull));

    if (typeof obj === 'object') {
      return Object.values(obj).some(value => this.hasData(value, allowNull));
    }
    // Default to false if no condition matches
    return false;
  }

  renderEventSourceIcon(
    eventSourceType: string,
    weight: 'l' | 's' | 'r' = 'l'
  ) {
    switch (eventSourceType) {
      case EventSourceTypes.AccountsFilingEventSourceType:
        return `fa${weight} fa-file-alt`;

      case EventSourceTypes.AccountsSearchEventSourceType:
        return `fa${weight} fa-search`;

      case EventSourceTypes.HiringEventSourceType:
        return `fa${weight} fa-user-tie`;

      case EventSourceTypes.NewsSearchEventSourceType:
        return `fa${weight} fa-newspaper fa-sm`;

      case EventSourceTypes.WebsiteSearchEventSourceType:
        return `fa${weight} fa-globe`;

      case EventSourceTypes.CorporateStructureEventSourceType:
        return `fa${weight} fa-sitemap fa-sm`;

      case EventSourceTypes.ImportExportEventSourceType:
        return `fa${weight} fa-file-import`;

      case EventSourceTypes.OfficerEventSourceType:
        return `fa${weight} fa-user`;

      case EventSourceTypes.PSCEventSourceType:
        return `fa${weight} fa-user-crown`;

      case EventSourceTypes.RelatedPSCEventSourceType:
        return `fa${weight} fa-users-crown`;

      case EventSourceTypes.RelatedOfficerEventSourceType:
        return `fa${weight} fa-users`;

      default:
        break;
    }
  }

  renderNavSectionIcon(navSection: string, weight: 'l' | 's' | 'r' = 'l') {
    switch (navSection) {
      case CompanyPageSectionEnums.CompanyOverView:
        return `fa${weight} fa-file-alt`;

      case CompanyPageSectionEnums.StrategicInsights:
        return `fa${weight} fa-lightbulb`;

      case CompanyPageSectionEnums.AIResearchAssistant:
        return `fa${weight} fa-microchip-ai`;

      case CompanyPageSectionEnums.PitchBuilder:
        return `fa${weight} fa-comments-alt-dollar`;

      case CompanyPageSectionEnums.KeyPeople:
        return `fa${weight} fa-user-friends`;

      case CompanyPageSectionEnums.Website:
        return `fa${weight} fa-browser`;

      case CompanyPageSectionEnums.Financials:
        return `fa${weight} fa-chart-bar`;

      case CompanyPageSectionEnums.AssetBreakdown:
        return `fa${weight} fa-car-building`;

      case CompanyPageSectionEnums.GeographicalRevenue:
        return `fa${weight} fa-yen-sign`;

      case CompanyPageSectionEnums.LatestNews:
        return `fa${weight} fa-newspaper`;

      case CompanyPageSectionEnums.HiringFor:
        return `fa${weight} fa-user-tie`;

      case CompanyPageSectionEnums.EventsAttending:
        return `fa${weight} fa-calendar-alt`;

      case CompanyPageSectionEnums.ImportsExports:
        return `fa${weight} fa-ship`;

      case CompanyPageSectionEnums.ShareInformation:
        return `fa${weight} fa-analytics`;

      case CompanyPageSectionEnums.Grants:
        return `fa${weight} fa-funnel-dollar`;

      case CompanyPageSectionEnums.IndustryPeerComparison:
        return `fa${weight} fa-chart-pie-alt`;

      case CompanyPageSectionEnums.IndustryInsights:
        return `fa${weight} fa-industry`;

      case CompanyPageSectionEnums.RecentLegislation:
        return `fa${weight} fa-gavel`;

      case CompanyPageSectionEnums.LegalEntityIdentifier:
        return `fa${weight} fa-barcode-read`;

      case CompanyPageSectionEnums.Trademarks:
        return `fa${weight} fa-trademark`;

      case CompanyPageSectionEnums.PatentsFiled:
        return `fa${weight} fa-book`;

      case CompanyPageSectionEnums.CompanyCharges:
        return `fa${weight} fa-receipt`;

      case CompanyPageSectionEnums.GovernmentContractsAwarded:
        return `fa${weight} fa-file-signature`;

      case CompanyPageSectionEnums.OnlineMarketplaces:
        return `fa${weight} fa-cart-shopping`;

      case CompanyPageSectionEnums.EnergyEmissions:
        return `fa${weight} fa-plug`;

      case CompanyPageSectionEnums.FunctionalDepartments:
        return `fa${weight} fa-id-badge`;

      case CompanyPageSectionEnums.CorporateStructure:
        return `fa${weight} fa-sitemap`;

      case CompanyPageSectionEnums.CrossSellOpportunities:
        return `fa${weight} fa-chart-network`;

      case CompanyPageSectionEnums.CorporateOfficers:
        return `fa${weight} fa-users-crown`;

      case CompanyPageSectionEnums.ProbableSuppliers:
        return `fa${weight} fa-parachute-box`;

      case CompanyPageSectionEnums.SimilarOrganisations:
        return `fa${weight} fa-vials`;

      case CompanyPageSectionEnums.CustomInsights:
        return `fa${weight} fa-microchip`;

      case CompanyPageSectionEnums.Sustainability:
        return `fa-kit fa-eco-energy fa-eco-icon`;

      case CompanyPageSectionEnums.VehicleOperatorLicences:
        return `fa${weight} fa-truck`;

      case CompanyPageSectionEnums.Shareholders:
        return `fa${weight} fa-chart-pie-simple-circle-dollar`;

      default:
        return `fa${weight} fa-hashtag`;
    }
  }

  getFormattingType(rowType: string = 'funds'): string {
    /**
     * this maps the formatting provided in the each row data to different types of formatting in company-data.pipe.
     * Default format is Revenue. Adjust/Add as and when.
     */
    const formatMapper = {
      funds: 'Revenue',
      energy: 'Total Energy Usage',
      emissions: 'Total Emissions',
      date: '',
      number: '',
      string: '',
    };

    return formatMapper[rowType] || rowType;
  }

  setCookieAndExpiry(cookieOptions: {
    name: string;
    value: string | Record<string, any>;
    expiry?: string | Date;
  }) {
    const { name, value, expiry } = cookieOptions;
    const jsonString = JSON.stringify(value);
    this.cookieService.put(name, jsonString, {
      expires: expiry,
      sameSite: 'strict',
      secure: true,
    });
  }
}
