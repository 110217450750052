<div class="modal fade" id="AiInsightsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Full Summaries
      </div>
      <div class="modal-body">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" *ngFor="let aiSummary of aiAccountsSummaries; let isFirst=first"
            style="cursor: pointer">
            <a class="nav-link" data-toggle="tab" role="tab"
              [href]="'#insights' + aiSummary.id"
              [ngClass]="{'active': isFirst}">
              Y.E. {{ aiSummary.made_up_to | date:'d MMM y' }}
            </a>
          </li>
        </ul>

        <div class="tab-content" style="width: 100%">            
        
          <div class="tab-pane" role="tabpanel" id="insights{{ aiSummary.id }}" *ngFor="let aiSummary of aiAccountsSummaries; let isFirst=first" [ngClass]="{'active' : isFirst, 'show' : isFirst}">
              @if (!aiSummary.id){
                  <p class="text-center mt-4">We have not processed a summary for these financials.</p>
              }
              @else{
                  @if (aiSummary.summary_state == 'skipped' || aiSummary.summary_state == 'error'){
                  <p class="text-center mt-4">There was not enough content in these financials for us to analyse.</p>
                  }
                  @else if (aiSummary.summary_state == 'processing' || aiSummary.summary_state == 'requested'){
                  <p class="text-center mt-4">We are currently processing the analysis of these financials.</p>
                  }
                  @else {
                    <ai-accounts-summaries [aiAccountSummary]="aiSummary" [companyId]="companyId"></ai-accounts-summaries>
                  }
              }


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
