import { Injectable, NgZone } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  private visibilityChange$: Observable<boolean>;
  delayInMs: number;

  constructor(private ngZone: NgZone) {
    this.initVisibilityChange();
  }

  private initVisibilityChange() {
    this.visibilityChange$ = fromEvent(document, 'visibilitychange').pipe(
      throttleTime(this.delayInMs), //delay before action
      map(() => {
        return !document.hidden;
      })
    );
  }

  getVisibilityChange(delay?: number): Observable<boolean> {
    this.delayInMs = delay || 200;
    return this.ngZone.runOutsideAngular(() => this.visibilityChange$);
  }
}
