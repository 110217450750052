@if (loadingStatus.loadingCompanyProfileData) {
  <zint-loader size="large" class="mx-auto"></zint-loader>
}

@else if (loadingError) {
  <div class="alert alert-danger text-center" role="alert">
    <h1>{{loadingError}}</h1>
    <span>Redirecting to the dashboard...</span>
  </div>
}

@else { 
<main class="page-container">
  <header class="hero">
    <div *ngIf="company.company_overview?.registered_address_lat_long_and_zoom" class="map-logo-container">
      <aol-map [width]="'100%'" [height]="'200px'">
        <aol-interaction-default></aol-interaction-default>
        <aol-view [zoom]="company.company_overview?.registered_address_lat_long_and_zoom.zoom_level">
          <aol-coordinate [x]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[1]"
            [y]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[0]"
            [srid]="'EPSG:4326'"></aol-coordinate>
        </aol-view>
        <aol-layer-tile>
          <aol-source-osm></aol-source-osm>
        </aol-layer-tile>
        <aol-layer-vector *ngIf="company.company_overview.registered_address_lat_long_and_zoom.show_point">
          <aol-source-vector>
            <aol-feature>
              <aol-geometry-point>
                <aol-coordinate [x]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[1]"
                  [y]="company.company_overview?.registered_address_lat_long_and_zoom.lat_long[0]"
                  [srid]="'EPSG:4326'"></aol-coordinate>
              </aol-geometry-point>
              <aol-style>
                <aol-style-circle [radius]="5">
                  <aol-style-stroke [color]="'black'" [width]="width"></aol-style-stroke>
                  <aol-style-fill [color]="'red'"></aol-style-fill>
                </aol-style-circle>
              </aol-style>
            </aol-feature>
          </aol-source-vector>
        </aol-layer-vector>
      </aol-map>

      <div class="logo">
        <img *ngIf="company.company_overview?.domain" class alt="logo"
          src="https://www.google.com/s2/favicons?sz=64&domain={{company.company_overview?.domain}}">
        <span class="logo-text" *ngIf="!company.company_overview?.domain">{{company.company_overview?.name | companyDataPipe:
          'company logo'}}</span>
      </div>
    </div>
    <div class="title-container">
      <div class>
        <h2 class="">
            {{company.company_overview?.name | titlecase}}
            <span class="status-badge"
              [ngClass]="company.company_overview?.company_status === 'Active' ? 'badge-active' : 'badge-inactive'">
              <i *ngIf="company.company_overview?.company_status !== 'Active'" class="fal fa-exclamation-triangle"></i>
              {{company.company_overview?.company_status}}
            </span>
            <span *ngIf="company?.has_viewed" class="eye"><i class="far fa-eye fa-2xs ml-2"
              matTooltip="You have viewed this company previously."></i>
            </span>

            <span class="ml-2">
              @if (loadingStatus['loadingCompanyUserDetails']){
              <zint-loader size="xSmall" [isInline]="true"></zint-loader>
              } @else {
              <span *ngIf="pubMethods.hasData(userCustomProperties)" style="font-size: 14px;">
                <heat-score [heatScore]="userCustomProperties.heat_score"></heat-score>
              </span>
              }
            </span>
        </h2>
        <p class="company-summary">{{company.company_overview?.summary}}</p>
      </div>
      <div class="socials">
        <a [href]="company.company_overview?.facebook_url" target="_blank"><i class="fab fa-facebook-f" 
          [ngStyle]="{'color': company.company_overview?.is_direct_facebook_url ?  '#1877f2': 'var(--zint-grey-400)'}"></i></a>
        <a [href]="company.company_overview?.twitter_url" target="_blank"><i class="fab fa-x-twitter"
          [ngStyle]="{'color': company.company_overview?.is_direct_twitter_url ?  '#14171a': 'var(--zint-grey-400)'}"></i></a>
        <a [href]="company.company_overview?.linkedin_url" target="_blank"><i class="fab fa-linkedin-in linkedIn-base-icon"
          style="vertical-align: 18%" [ngClass]="company.company_overview?.is_direct_linkedin_url ? 'linkedIn-icon-direct' : 'linkedIn-icon-search'"></i></a>
      </div>
    </div>

    <div class="hero-btns-container flex-column flex-sm-row">

      <button class="rounded-btn-base rounded-btn-vivid btn-p-xl" (click)="getStrategicAnalysis()">
        <i class="fa-kit fa-ai-growth mr-1"></i>
          Analyse</button>
      
      @if (!featurePermissions.hasZintGrowAccess || zintGrowColumns.length === 0) {
        <button class="rounded-btn-base rounded-btn-vivid btn-p-xl" data-toggle="modal" data-target="#zintGrowVideoModal">
          <span><i class="fal fa-bolt"></i>
            &nbsp;Zint Grow</span>
        </button>
      }
      @else if (zintGrowColumnsFollowing && zintGrowColumnsFollowing.length > 0) {
        <button class="rounded-btn-base rounded-btn-light btn-p-xl" (click)="unfollowCompanyInZintGrow()">
          <span><i class="fal fa-times"></i>
            &nbsp;Unfollow</span>
        </button>
      } 
      @else {
        <button class="rounded-btn-base rounded-btn-light btn-p-xl" (click)="initFollowZintGrow()">
          <span><i class="fal fa-bolt"></i>
            &nbsp;Follow</span>
        </button>
      }
     

      <div class="btn-group tag-btn-group" role="group">
        <button type="button" class="btn" matTooltip="Tag Company" data-toggle="modal" data-target="#tagModal"><i class="fal fa-tag fa-rotate-by mr-1" style="--fa-rotate-angle: 135deg;"></i></button>
        <button [disabled]="!featurePermissions.hasSalesforceV2Access" type="button" class="btn" [matTooltip]="featurePermissions.hasSalesforceV2Access ? 'Salesforce Export' : 'Permission required to use this feature'" data-toggle="modal" data-target="#exportToSalesforceModal"><i class="fa-brands fa-salesforce"></i></button>
        <button [disabled]="!featurePermissions.hasHubspotAccess" type="button" class="btn" [matTooltip]="featurePermissions.hasHubspotAccess ? 'Hubspot Export' : 'Permission required to use this feature'" data-toggle="modal" data-target="#exportToHubspotModal"><i class="fa-brands fa-hubspot"></i></button>
      </div>

    </div>
  </header>

  <nav class="sticky-nav-wrapper" [ngClass]="{'sticky-nav-wrapper-all': toggleAllNavSections, 'nav-loading': loadingStatus.loadingCompanyProfileData}">
   
    <div class="nav-container" [ngClass]="{'nav-container-all': toggleAllNavSections}">
      <div *ngIf="!toggleAllNavSections" class="nav-items">        
        <h6 role="button" *ngFor="let nav of allNavSections" class="mr-4 nav-item-each"
          [ngClass]="handleNavStyle(nav)" [routerLink]="[]" [fragment]="nav+'_section'">
          <i class="mr-1" [ngClass]="getSectionIcon(nav)"></i>
          @if (showPremiumIcon(nav)) {
          <a (click)="visitZintPage(nav)" class="text-grey-600">{{nav | underscoreToUpper}}</a>
          }
          @else {
          {{nav | underscoreToUpper}}
          }
          <sup class="ml-1">{{navSectionPolledCount[nav] | companyDataPipe: 'profileNavCount'}}</sup>
          <i *ngIf="showPremiumIcon(nav)" matTooltip="Premium" matTooltipPosition="above" class="ml-1 fal fa-circle-dollar" (click)="visitZintPage(nav);$event.stopPropagation()"></i>
        </h6>      
      </div>
      <div class="see-all-navs-container" [ngClass]="{'no-gradient': toggleAllNavSections}">
        <h6 role="button" (click)="showAllNavSections()">See all
          <i class="ml-1 fas"
            [ngClass]="toggleAllNavSections ? 'fa-caret-up' : 'fa-caret-down'"></i>
        </h6>
        <button class="rounded-btn-base rounded-btn-vivid-outline btn-p-sm align-self-end"
          *ngIf="toggleAllNavSections"
          (click)="reOrderNavSections()">
          <i class="fal fa-bars-sort fa-xs"></i>&nbsp;Reorder
        </button>    
      </div>

      <div *ngIf="toggleAllNavSections" class="nav-items-show-all"
        [ngClass]="{'bg-light': isReordering, 'scrollbar-show': isSmallScreen}"
        >
        <h6 role="button" *ngFor="let nav of allNavSections; let index = index"
          class="nav-item-each" [ngClass]="handleNavStyle(nav)"
          [routerLink]="[]" [fragment]="nav+'_section'" (click)="collapseAllNavs()">
          <i class="mr-1" [ngClass]="getSectionIcon(nav)"></i>
          @if (showPremiumIcon(nav)) {
            <a (click)="visitZintPage(nav)" class="text-grey-600">{{nav | underscoreToUpper}}</a>
          } 
          @else {
            {{nav | underscoreToUpper}}
          }
          <sup class="ml-1">{{navSectionPolledCount[nav] | companyDataPipe: 'profileNavCount'}}</sup>
          <i *ngIf="showPremiumIcon(nav)" matTooltip="Premium" matTooltipPosition="above" class="ml-1 fal fa-circle-dollar"
            (click)="visitZintPage(nav);$event.stopPropagation()"></i>
        </h6>
      </div>
     
    </div>
  </nav>

  
  <section class="sections-container">
    <div class="expand-btn-container-right">
      <button class="rounded-btn-base rounded-btn-vivid btn-p-sm expand-btn" (click)="toggleAccordion()">
        <span>
          @if (isAllExpansionPanelsOpen) {
          <i class="fal fa-arrows-to-line mr-2"></i>
          Collapse All
          }
          @else {
          <i class="fal fa-arrows-from-line mr-2"></i>
          Expand All
          }
        </span>
      </button>
    </div>

    <mat-accordion *ngIf="allNavSections?.length" multi class="d-flex flex-column">
    <mat-expansion-panel id="company_overview_section" class="order-first" hideToggle expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
                    
            <h4><i class="mr-1" [ngClass]="getSectionIcon('company_overview')"></i> Company Overview
            <i *ngIf="expandedSections['company_overview_section']" class="ml-1 far fa-angle-up"></i>
            </h4>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="grid-container">
        <div class="grid-container-left-col">
          <div class="grid-sics-and-addresses">
            <div *ngIf="company.company_overview?.sic_codes.length" class="grid-sic-codes card border-rounded-05">
              <div class="text-info-card">
                <div id="companyCarousel" class="carousel slide custom-carousel"
                  [ngStyle]="{'height': company.company_overview?.sic_codes?.length < 2 ? '8rem' : '10rem'}"
                  data-ride="carousel" data-interval="false">
                  <!-- indicators & controls -->
                  <div class="controls-wrapper" [ngClass]="{'d-none': company.company_overview?.sic_codes?.length < 2}">
                    <a class="custom-control-left" href="#companyCarousel" data-slide="prev">
                      <i role="button" class="fas fa-caret-circle-left"></i>
                    </a>
                    <ol class="carousel-indicators">
                      <li *ngFor="let sicCode of company.company_overview?.sic_codes; let i = index"
                        [class.active]="i === 0"
                        data-target="#companyCarousel" [attr.data-slide-to]="i">
                      </li>
                    </ol>
                    <a class="custom-control-right" href="#companyCarousel" data-slide="next">
                      <i role="button" class="fas fa-caret-circle-right"></i>
                    </a>
                  </div>

                  <!-- carousel contents/slides -->
                  <div class="carousel-inner">
                    <div *ngFor="let sicCode of company.company_overview?.sic_codes; let i = index"
                      class="carousel-item"
                      [ngClass]="{'active': i === 0}">
                      <div class="card">
                        <h6>{{sicCode.description}}</h6>
                        <span class>
                          <i class="fa-duotone fas fa-industry-windows mr-1"></i>
                          <b>{{sicCode.code}}</b> - {{sicCode.section}}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="keywords-container">
                  <h5>Company Keywords</h5>
                  <div>
                    @for (keyword of company.company_overview?.keywords; track $index) {
                      <span class="badge-company-keywords mr-2 mb-2">{{keyword}}</span>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="addresses-card card border-rounded-05"
              [ngClass]="{'grid-addresses': company.company_overview?.registered_address && company.company_overview?.trading_addresses}">
            
              <div class="registered-address-container">
                <h5 class="border-bottom px-3 py-2 text-nowrap"><i class="fal fa-map-marker-alt mr-1"></i>Registered Address</h5>
                <div class="px-3 py-2">
                  <span class="line-break-text address-each d-inline-block">{{
                    company.company_overview?.registered_address }} </span>
            
                </div>
              </div>
            
              <div class="trading-address-container">
                <h5 class="border-bottom px-3 py-2 sticky-top"><i class="fal fa-map-marker-alt mr-1"></i>Trading Addresses</h5>
                <div class="trading-addresses-list">
            
                  <div *ngFor="let ta of company.company_overview?.trading_addresses;" class="address-each">
            
                    <span class="line-break-text">{{ ta }}</span>
            
                  </div>
                </div>
              </div>
            
            </div>
          </div>

          <div class="grid-custom-properties card border-rounded-05 scrollbar-show" [ngClass]="{'border-green-500': customAttrsUpdated, 'grid-span-2': !company.company_overview?.sic_codes.length}">
            @if (loadingStatus.loadingCompanyUserDetails) {
              <zint-loader size="small"></zint-loader>
            }
            @if (salesforceLinks && salesforceLinks.length > 0) {                  
              @for (salesforceLink of salesforceLinks; track $index) {            
                <a class="rounded-btn-base rounded-btn-light btn-p-md btn-link mb-2"
                  href="{{salesforceLink}}" target="_blank">
                  <span>View record on Salesforce <i class="fal fa-external-link fa-xs"></i></span>
                </a>
                }                 
            }
              <div *ngIf="userCustomProperties.hasSomeData && !loadingStatus.loadingCompanyUserDetails" class="custom-props-container">                                
                <mat-expansion-panel *ngIf="getTagsTotal()" togglePosition="before">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span class="custom-props-title"><i class="far fa-tag mr-1"></i>Existing Tags
                      </span>
                    </mat-panel-title>

                    <span class="badge-custom-props badge-propensity-score align-self-baseline">{{getTagsTotal()}}</span>
                  </mat-expansion-panel-header>
                  
                  <div>
                    <div class="mb-1">
                      @for (tag of userCustomProperties?.tags; let i = $index; track i) {

                        <span *ngIf="i === 0" class="text-grey-600">Your Tags:&nbsp;</span>

                        @if(!tag.is_propensity) {

                          <span class="badge-custom-props"
                          [ngStyle]="{'background-color': '#'+tag.colour}">
                          {{tag.name}}&nbsp;
                          <i class="fal fa-times remove-tag-icon" role="button" matTooltip="remove tag" (click)="removeTag(tag.tag_id)"></i>
                        </span>
                        }
                      }
                    </div>

                    <div class="mb-1">
                      @for (tag of userCustomProperties?.team_member_tags; let i = $index; track i) {

                      <span *ngIf="i === 0" class="text-grey-600">Colleagues Tags:&nbsp;</span>

                        @if(!tag.is_propensity){
                          <span class="badge-custom-props"
                            [ngStyle]="{'background-color': '#'+tag.colour}">{{tag.name}}</span>
                        }
                      }
                    </div>

                    <div class="mb-1">
                      @for (tag of userCustomProperties?.organisation_tags; let i = $index; track i) {
                      <span *ngIf="i === 0" class="text-grey-600">Shared Tags:&nbsp;</span>

                        @if(!tag.is_propensity){

                          <span class="badge-custom-props"
                            [ngStyle]="{'background-color': '#'+tag.colour}">
                            {{tag.name}}
                            <i *ngIf="tag.can_edit" class="fal fa-times remove-tag-icon" role="button"
                              matTooltip="remove tag" (click)="removeTag(tag.tag_id)"></i>
                          </span>
                        }
                      }
                    </div>

                  </div>
                </mat-expansion-panel>

                <mat-expansion-panel *ngIf="userCustomProperties?.cross_sell_reason"
                  togglePosition="before" expanded>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span class="custom-props-title">
                      <i class="far fa-link mr-1"></i>
                      Cross Sell Connections
                      </span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <span class="text-grey-600">{{userCustomProperties.cross_sell_reason}}</span>
                </mat-expansion-panel>

                <div class="">
                  @for (customColumn of userCustomProperties.custom_columns; let customColumnIndex = $index; track customColumnIndex)
                  {
                    <ng-container *ngIf="customColumn.is_propensity ? customColumn.value : true">
                      <mat-expansion-panel togglePosition="before" expanded>
                        <mat-expansion-panel-header>
                          <mat-panel-title>

                            @if(customColumn.is_propensity && customColumn.value){
                              <i class="far fa-star mr-1"></i>
                            }
                            @else if (customColumn.is_salesforce) {                        
                              <i class="fab fa-salesforce salesforce-icon mr-1"></i>
                            }
                            @else if (customColumn.is_lockable) {                          
                              <i class="fal fa-lock mr-1"></i>
                            }

                            <span class="custom-props-title">{{customColumn.name}}</span>

                          </mat-panel-title>

                          <span *ngIf="customColumn.is_propensity && customColumn.value"
                            class="badge-custom-props badge-propensity-score align-self-baseline">{{ customColumn.value |
                            number:'1.1-1' }}&nbsp;%</span>

                        </mat-expansion-panel-header>

                        
                        @if (customColumn.is_propensity === true && customColumn.value) {

                          @for (scoreTag of userCustomProperties?.propensity_score_tags; track $index) {
                          <span
                            *ngIf="scoreTag?.propensity_column_id === customColumn.id"
                            class="badge-custom-props"
                            [matTooltip]="scoreTag?.propensity_component_annotation"
                            [ngStyle]="getPropensityComponentStyle(scoreTag)"
                            (click)="openSearchPreview(scoreTag)"
                            >
                            <i *ngIf="showPropensitySearchLink(scoreTag)" class="fal fa-external-link"></i>
                            {{scoreTag?.name}}
                          </span>
                          }
                        }
                        
                        
                        @if (customColumn.is_propensity === false) {
                          
                          <custom-column-edit [inputParams]="{
                            'customColumn': customColumn,
                            'companyIds': [company.company_id],
                            'displayTight': false,
                            'isSuperUser': accountDetails.isSuperUser,
                            'lockablePermittedEditValue': accountDetails.email,
                            'teamMembers': teamMembers,
                            'secondDatePickerIndex': customColumnIndex }"
                            (onCustomColumnSaved)="showUpdatedCustomAttrsMessage($event[0])"
                            >
                          </custom-column-edit>

                          @if(customColumn.is_lockable && (customColumn.is_locked_indefinitely || customColumn.unlock_datetime)) {
                            <div class="unlock-date">

                              @if (customColumn.is_locked_indefinitely && customColumn.value) {
                                <span>Locked</span>
                              }

                              @if (customColumn.unlock_datetime) { 
                                <span>Unlock Date:
                                {{customColumn.unlock_datetime | date:'d MMM y'}}
                                </span>
                              }
                            </div>
                          }

                          @if(customAttrsUpdated) {
                            <span class="text-grey-600">Updated 
                              <i class="fal fa-check"></i>
                            </span>
                          }
                          
                      }

                      </mat-expansion-panel>
                    </ng-container>
                  }
                </div>
              </div>
          </div>
          
        </div>
        <div class="grid-company-snapshot card border-rounded-05">
          <table class="snapshot-table">            

            <tr *ngIf="company.company_overview?.number">
              <td><i class="fal fa-hashtag"></i>Company Number</td>
              <td>{{ company.company_overview?.number }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.quarter_statement">
              <td><i class="fal fa-circle-three-quarters"></i>Quarter</td>
              <td>{{ company.company_overview?.quarter_statement }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.age">
              <td><i class="fal fa-stopwatch"></i>Age</td>
              <td>{{ company.company_overview?.age }} old</td>
            </tr>

            <tr *ngIf="company.company_overview?.ubos && company.company_overview?.ubos.length > 0">
              <td><i class="fal fa-cubes"></i>UBOs</td>
              <td>
                @for (ubo of company.company_overview?.ubos; let i = $index; track i) {
                
                  <div style="display:inline" [ngStyle]="{'margin-left': (i > 0)? '44px' : '0px' }">
                    <a href="/profile/{{ubo.company_number}}" target="_blank"
                      *ngIf="ubo.company_number">
                      <span [ngClass]="'flag-icon flag-icon-' + ubo.country_code"
                        style="margin-left: 5px; margin-right: 5px"
                        matTooltipPosition="below" matTooltip="{{ ubo.country_name }}"></span> {{
                      ubo.name }} <br>
                    </a>
                    @if (!ubo.company_number) {
                      <span [ngClass]="'flag-icon flag-icon-' + ubo.country_code"
                        style="margin-left: 5px; margin-right: 5px"
                        matTooltipPosition="below" matTooltip="{{ ubo.country_name }}"></span> {{
                      ubo.name }} <br>
                    }
                  </div>
                }
              </td>
            </tr>

            <tr *ngIf="company.company_overview?.phone_number">
              <td><i class="fal fa-phone"></i>Phone</td>
              <td> {{ company.company_overview?.phone_number }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.email">
              <td><i class="fal fa-envelope"></i>Email</td>
              <td> {{ company.company_overview?.email }} </td>
            </tr>

            <tr *ngIf="company.company_overview?.headcount">
              <td><i class="fal fa-users"></i>Headcount</td>
              <td>{{ company.company_overview?.headcount.total }} employees<span
                  *ngIf="company.company_overview?.headcount.is_estimated">, estimated</span> <span
                  *ngIf="company.company_overview?.is_in_group"> (is part of a
                  group)</span></td>
            </tr>

            <tr *ngIf="company.company_overview?.url || company.company_overview?.domain">
              <td><i class="fal fa-globe"></i>Website</td>
              <td><a href="{{ company.company_overview?.url }}" target="_blank"> {{
                  company.company_overview?.domain }}</a></td>
            </tr>

            <tr *ngIf="company.company_overview?.revenue">
              <td><i class="fa-light fa-money-bill"></i>Revenue</td>
              <td>{{ company.company_overview?.revenue }}</td>
            </tr>

            <tr *ngIf="company.company_overview?.assets_growth_percentage">
              <td><i class="fal fa-chart-line-up-down"></i>Assets Growth</td>
              <td><span class="badge"
                  [ngClass]="{'badge-danger' : company.company_overview?.assets_growth_percentage < 0, 'badge-success' : company.company_overview?.assets_growth_percentage > 0}">{{
                  company.company_overview?.assets_growth_percentage.toPrecision(2) | number }}%</span></td>

            </tr>

            <tr *ngIf="company.company_overview?.revenue_growth_percentage">
              <td><i class="fal fa-money-bill-trend-up"></i>Revenue Growth</td>
              <td><span class="badge"
                  [ngClass]="{'badge-danger' : company.company_overview?.revenue_growth_percentage < 0, 'badge-success' : company.company_overview?.revenue_growth_percentage > 0}">{{
                  company.company_overview?.revenue_growth_percentage.toPrecision(2) | number }}%</span></td>
            </tr>

            <tr *ngIf="company.company_overview?.gross_profit_growth_percentage">
              <td><i class="fal fa-chart-mixed-up-circle-dollar"></i>Gross Profit Growth</td>
              <td><span class="badge"
                  [ngClass]="{'badge-danger' : company.company_overview?.gross_profit_growth_percentage < 0, 'badge-success' : company.company_overview?.gross_profit_growth_percentage > 0}">{{
                  company.company_overview?.gross_profit_growth_percentage.toPrecision(2) | number }}%</span></td>
            </tr>
          </table>
        </div>
      </div>

    </mat-expansion-panel>

    @if(permissionsCheckMap.ai_research_assistant){
      <mat-expansion-panel id="ai_research_assistant_section" [style.order]="getSectionOrder('ai_research_assistant')" hideToggle
        (expandedChange)="setSectionExpandedState('ai_research_assistant_section')">
        <mat-expansion-panel-header>
          <mat-panel-title>
      
            <h4><i class="mr-1" [ngClass]="getSectionIcon('ai_research_assistant')"></i>AI Assistant
              <i *ngIf="expandedSections['ai_research_assistant_section']" class="ml-1 far fa-angle-up"></i>
            </h4>
      
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="max-height-75vh">
          <ai-research-engine [companyId]="companyNumber" [enableOpenQuestionChatBot]="permissionsCheckMap.ai_company_assistant" [newUI]="true">
          </ai-research-engine>
        </div>
      </mat-expansion-panel>
    }

    @if (permissionsCheckMap.custom_insights) {
        <mat-expansion-panel id="custom_insights_section" [style.order]="getSectionOrder('custom_insights')" hideToggle
          (expandedChange)="setSectionExpandedState('custom_insights_section'); getCustomInsights()">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4>
                <i class="mr-1" [ngClass]="getSectionIcon('custom_insights')"></i>
                Custom Insights
                <i *ngIf="expandedSections['custom_insights_section']" class="ml-1 far fa-angle-up"></i>
              </h4>
            </mat-panel-title>
            <button class="rounded-btn-base rounded-btn-light text-grey-600" (click)="openCustomInsightsModal(); $event.stopPropagation();" matTooltip="&midast;&nbsp;Uses your Zint AI Credit Quota">Generate Custom Insights<sup>*</sup></button>
          </mat-expansion-panel-header>
            @if (loadingStatus.loadingCustomInsights) {
                <div class="my-1">
                  <zint-loader></zint-loader>
                </div>
            }@else {
                @if (customInsights.length === 0){
                    <div>
                        <p>There are no custom insights available for this company. You can request them here:</p>
                        <br>
                        <button class="rounded-btn-base rounded-btn-light text-grey-600" (click)="openCustomInsightsModal()" matTooltip="&midast;&nbsp;Uses your Zint AI Credit Quota">Generate Custom Insights<sup>*</sup></button>
                    </div>
                }
                    @for (customInsightGroup of customInsights; track $index) {
                  <h4 class="mt-4 text-center">Insights Generated: {{customInsightGroup.generated | date:'d MMM y'}}</h4>
                  <div class="cards-list-container-no-overflow">
                      @for (customInsight of customInsightGroup.insights; track $index) {
                          <div class="profile-element-card">
                              <div class="d-sm-flex justify-content-between">
                                  @if (customInsight.header) {
                                      <div>
                                          <h5>{{customInsight.header}}</h5>
                                      </div>
                                  }
                                  @if (customInsight.product_or_service){
                                      <div>
                                          <span class="badge-light-grey mr-2 mb-2">{{ customInsight.product_or_service }}</span>
                                      </div>
                                  }
                              </div>
                              <div class="d-flex my-3 flex-wrap">
                                    @if (customInsight.dialogue) {
                                        <p><i class="fal fa-comments"></i> {{customInsight.dialogue}}</p>
                                    }
                                    @if (customInsight.quotation) {
                                        <div class="quotation-block">
                                            <span>
                                                <i class="fal fa-quote-left"></i>
                                                    <span class="font-italic"> {{customInsight.quotation}} </span>
                                                <i class="fal fa-quote-right"></i>
                                                <span>
                                                    &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;<i class="fal fa-calendar"></i> {{ customInsight.date_of_quotation | date:'d MMM y' }}&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </span>
                                        </div>
                                    }
                              </div>
                          </div>
                      }
                  </div>
              }
            }
        </mat-expansion-panel>
    }

    @if (permissionsCheckMap.strategic_insights) {
      <mat-expansion-panel id="strategic_insights_section" [style.order]="getSectionOrder('strategic_insights')" hideToggle (expandedChange)="setSectionExpandedState('strategic_insights_section')" [disabled]="navSectionPolledCount['strategic_insights'] < 1">
        <mat-expansion-panel-header>
          <mat-panel-title>
            
              <h4>
                <i class="mr-1" [ngClass]="getSectionIcon('strategic_insights')"></i>
                Strategic Insights
                <sup class="section-counter-badge">
                  {{navSectionPolledCount['strategic_insights']}}
                </sup>
                <i *ngIf="expandedSections['strategic_insights_section']" class="ml-1 far fa-angle-up"></i>
              </h4>
            
          </mat-panel-title>
          <button *ngIf="pubMethods.hasData(company.strategic_insights)" class="rounded-btn-base rounded-btn-light text-grey-600" matTooltip="Full summaries of available year accounts"
            (click)="openInsightsModal(); $event.stopPropagation();">Read more</button>
        </mat-expansion-panel-header> 
            @if (pubMethods.isEmptyObjectOrArray(company.strategic_insights)) {
              <span class="text-grey-600">No summaries have been generated yet.</span>
            }
            
            @if (company.strategic_insights?.length) {
                <ai-accounts-summaries
                  [aiAccountSummary]="company.strategic_insights[0]"
                  [companyId]="companyNumber">
              </ai-accounts-summaries>
            }
      </mat-expansion-panel>
  } 

  @if (permissionsCheckMap.pitch_builder) {
  <mat-expansion-panel id="pitch_builder_section" [style.order]="getSectionOrder('pitch_builder')" hideToggle
    (expandedChange)="setSectionExpandedState('pitch_builder_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('pitch_builder')"></i>
            Bespoke Analysis with Zint AI
            <i *ngIf="expandedSections['pitch_builder_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
  
    @if (loadingStatus.loadingAIPitchBuilderSettings) {
    <zint-loader size="small"></zint-loader>
    <span>Configuring...</span>
    }
    @else if (!loadingStatus.loadingAIPitchBuilderSettings) {
    <button type="button" class="rounded-btn-base rounded-btn-vivid btn-p-lg" (click)="generateAiPitch()"
      [attr.disabled]="loadingStatus.loadingAIPitch ? 'disabled': null">
      Generate AI pitch
      <zint-loader *ngIf="loadingStatus.loadingAIPitch === true" size="xxSmall" dotColor="white"
        [isInline]="true"></zint-loader>
    </button>
    }
  
    @if (companyAIPitch) {
    <div class="card max-height-75vh border-rounded-05 mt-3">
      <div class="card-body text-grey-600" [innerText]="companyAIPitch"></div>
    </div>
    }
  </mat-expansion-panel>
  }
  
  <mat-expansion-panel id="key_people_section" [style.order]="getSectionOrder('key_people')" hideToggle (expandedChange)="setSectionExpandedState('key_people_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          
            <h4>
              <i class="mr-1" [ngClass]="getSectionIcon('key_people')"></i>
              Key People
              <sup class="section-counter-badge">
                {{navSectionPolledCount['key_people']}}
              </sup>
              <i *ngIf="expandedSections['key_people_section']" class="ml-1 far fa-angle-up"></i>
            </h4>
        
        </mat-panel-title>
       
      </mat-expansion-panel-header>
      
      <div class="row justify-content-around key-people-container">
        @for (person of company.key_people; let count = $count; track $index) {

          @if ($index <= 6 ) {
            <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30">              
              <key-people [person]="person" [personIndex]="$index"></key-people>              
            </div>
          }

          @if ($index === 7 && count > 8) {
            <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30">
              <key-people [showAllPeople]="true" [totalCount]="count"></key-people>
            </div>
          }

          @if ($index === 7 && count === 8) {
            <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30"> 
              <key-people [person]="person" [personIndex]="$index"></key-people>         
            </div>
          }
          
        }        
      </div>
  </mat-expansion-panel>
    
  @if (pubMethods.hasData(company.website)) {
      <mat-expansion-panel id="website_section" [style.order]="getSectionOrder('website')" hideToggle (expandedChange)="setSectionExpandedState('website_section')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            
              <h4>
                <i class="mr-1" [ngClass]="getSectionIcon('website')"></i>
                Website
                <sup class="section-counter-badge">
                  {{navSectionPolledCount['website']}}
                </sup>
                <i *ngIf="expandedSections['website_section']" class="ml-1 far fa-angle-up"></i>
              </h4>
            
          </mat-panel-title>
          <button class="rounded-btn-base rounded-btn-light text-grey-600"
            (click)="openSearchModal('website-search'); $event.stopPropagation()">
            <i class="fal fa-search"></i>            
            Search this website
          </button>
        </mat-expansion-panel-header>
        
          <div class="website-section-container">

            <div class="website-left-col">
              @defer (when company.website.website_screenshot) {                            
              <img src="{{ company.website.website_screenshot }}" onError="this.src='https://placehold.co/600x400/f5f4f8/lightgrey?text=no+webpage+preview'
">
              }

                <div class="website-snapshot-container">
                  <table class="snapshot-table">
                    <tr>
                      <td>Website</td>
                      <td>
                        <a href="{{ company.website?.url }}" target="_blank">{{ company.website?.domain }}</a>
                      </td>
                    </tr>

                    @if (company.website?.associated_websites && company.website?.associated_websites.length > 0) {
                      <tr>
                        <td>Associated Websites</td>
                        <td>
                        @for (site of company.website?.associated_websites; track $index) {
                          <a href="{{ site.url }}" target="_blank" class="mr-2">{{ site.domain_name
                            }}</a>
                        }
                        </td>
                      </tr>
                    }
                    <tr *ngIf="company.website?.monthly_website_visitors">
                        <td>Monthly Visitors</td>
                        <td>{{ company.website?.monthly_website_visitors | number }}</td>
                    </tr>
                  </table>
                  
                </div>
            </div>

            <div *ngIf="company.website?.web_technologies" class="website-right-col">
              <h5>Website Technologies</h5>
                <table class="snapshot-table">
                  @for (web_tech_cat_to_techs of company.website?.web_technologies | mapToIterable; track $index) {
                    <tr>
                      <td>{{web_tech_cat_to_techs.key}}</td>

                      <td class="pt-2">
                      @for (tech of web_tech_cat_to_techs.val; track $index) {
                          <span class="badge-web-tech">
                            <img src="/assets/tech-icons/{{ tech.icon }}" onError="this.src='/assets/tech-icons/tachometer-alt.png'" style="height: 16px">
                            {{ tech.name }} 
                            <span *ngIf="tech.version">(Version {{ tech.version
                              }})
                            </span>
                          </span>
                        }
                      </td>
                    </tr>
                  }
                </table>            
            </div>
          </div>
      
      </mat-expansion-panel>
  }

  @if (pubMethods.hasData(this.company.financials)) {
    <mat-expansion-panel id="financials_section" [style.order]="getSectionOrder('financials')" hideToggle
      (expandedChange)="setSectionExpandedState('financials_section')" class="mb-5">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('financials')"></i>
              Financials
            <sup class="section-counter-badge">
              {{navSectionPolledCount['financials']}}
            </sup>
            <i *ngIf="expandedSections['financials_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </mat-panel-title>
        <button class="rounded-btn-base rounded-btn-light text-grey-600"
          (click)="openSearchModal('accounts-search'); $event.stopPropagation()"
          [disabled]="!this.pubMethods.hasData(this.company.financials.accounts)">
          <i class="fal fa-search"></i>
          Search Company Accounts
        </button>
      </mat-expansion-panel-header>
      
      <div>
       @for (KV of this.company.financials.accounts | keyvalue: originalOrder; track $index) {
        @if (KV.value.rows.length) {
        <mat-expansion-panel togglePosition="before" expanded class="p-0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5 class="mb-0">
                {{KV.key}}
              </h5>
            </mat-panel-title>
          </mat-expansion-panel-header>
        
          <backend-table [tableData]="KV.value" [formatting]="'Revenue'" [showTrend]="true"></backend-table>
        </mat-expansion-panel>
       }
      }
      </div>
      </mat-expansion-panel>
  }

  @if (pubMethods.hasData(company.asset_breakdown)) {
    <mat-expansion-panel id="asset_breakdown_section" [style.order]="getSectionOrder('asset_breakdown')" hideToggle (expandedChange)="setSectionExpandedState('asset_breakdown_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          
            <h4>
              <i class="mr-1" [ngClass]="getSectionIcon('asset_breakdown')"></i>
              Assets Breakdown
              <sup class="section-counter-badge">                                
                {{navSectionPolledCount['asset_breakdown']}}
              </sup>
              <i *ngIf="expandedSections['asset_breakdown_section']" class="ml-1 far fa-angle-up"></i>
            </h4>
          
        </mat-panel-title>      
      </mat-expansion-panel-header>
    
      @if (availableAssetsData.availableAssetType === 'both') {
        @for (assetType of ['tangible', 'intangible']; track $index) {
          <mat-expansion-panel togglePosition="before" expanded>
            <mat-expansion-panel-header>
              <span class="custom-props-title">
                {{assetType | titlecase}} Assets
              </span>
            </mat-expansion-panel-header>

            <div class="financials-table-container max-height-40vh" [ngClass]="{'scrollbar-show': availableAssetsData[assetType]?.length > 6}">
              <table class="table financials-table table-layout-fixed">
                <thead>
                  <tr class="header-row">
                    <th scope="col" class="sticky-col">Assets</th>
                    @for (kV of company.asset_breakdown | keyvalue: originalOrder; track $index) {
                      <th>Y.E.&nbsp;{{kV.key | date: 'd MMM y'}}</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  @for (term of availableAssetsData[assetType]; track $index) {
                    <tr>
                      <th scope="row" class="sticky-col">
                        {{term | underscoreToUpper}}
                      </th>
      
                      @for (yearAccKV of company.asset_breakdown | keyvalue; track $index) {
                        @if (yearAccKV.value[assetType] && yearAccKV.value[assetType][term]) {
                          <td>{{yearAccKV.value[assetType][term] | companyDataPipe: 'Assets'}}</td>                        
                        }
                        @else {
                          <td>&#65343;&#65343;&#65343;</td>
                        }
                      }
                    </tr>
                  }                
                </tbody>
              </table>            
            </div>
          </mat-expansion-panel>
        }
      }
      @else {
        <div class="financials-table-container max-height-50vh" [ngClass]="{'scrollbar-show': availableAssetsData[availableAssetsData.availableAssetType]?.length > 8}">
          <table class="table financials-table">
            <thead>
              <tr class="header-row">
                <th scope="col" class="sticky-col">{{availableAssetsData.availableAssetType | titlecase}} Assets</th>
                @for (kV of company.asset_breakdown | keyvalue; track $index) {
                <th>Y.E.&nbsp;{{kV.key | date: 'd MMM y'}}</th>
                }
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="availableAssetsData.availableAssetType as onlyAssetType">
                @for (term of availableAssetsData[onlyAssetType]; track $index) {
                <tr>
                  <th scope="row" class="sticky-col">
                    {{term | underscoreToUpper}}
                  </th>
          
                  @for (yearAccKV of company.asset_breakdown | keyvalue: originalOrder; track $index) {
                  @if (yearAccKV.value[onlyAssetType] && yearAccKV.value[onlyAssetType][term]) {
                  <td>{{yearAccKV.value[onlyAssetType][term] | companyDataPipe: 'Assets'}}</td>
                  }
                  @else {
                  <td>&#65343;&#65343;&#65343;&#65343;</td>
                  }
                  }
                </tr>
                }
              </ng-container>
            </tbody>
          </table>
        </div>
      }
    </mat-expansion-panel>
  }

  @if (company.geographical_revenue?.length) {
    <mat-expansion-panel id="geographical_revenue_section" [style.order]="getSectionOrder('geographical_revenue')" hideToggle (expandedChange)="setSectionExpandedState('geographical_revenue_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
       
            <h4>
              <i class="mr-1" [ngClass]="getSectionIcon('geographical_revenue')"></i>
              Geographical Revenue
              <sup class="section-counter-badge">
                {{navSectionPolledCount['geographical_revenue']}}
              </sup>
              <i *ngIf="expandedSections['geographical_revenue_section']" class="ml-1 far fa-angle-up"></i>
            </h4>
          
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="financials-table-container">
        <table class="table financials-table table-layout-fixed">
          <thead>
            <tr class="header-row">
              <th scope="col" class="sticky-col">Regions</th>
              <th scope="col">Revenue</th>
            </tr>
          </thead>
          <tbody>
            @for (item of company.geographical_revenue; track $index) {
              <tr>
                <th scope="row" class="sticky-col">{{item.name}}</th>
                <td>{{item.revenue | companyDataPipe: 'Revenue'}}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </mat-expansion-panel>
  }

  @if (company.vehicle_operator_licences?.length) {
  <mat-expansion-panel id="vehicle_operator_licences_section" [style.order]="getSectionOrder('vehicle_operator_licences')" hideToggle (expandedChange)="setSectionExpandedState('vehicle_operator_licences_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('vehicle_operator_licences')"></i>
          Vehicle Operator Licences
          <sup class="section-counter-badge">            
            {{navSectionPolledCount['vehicle_operator_licences']}}
          </sup>
          <i *ngIf="expandedSections['vehicle_operator_licences_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>        
    </mat-expansion-panel-header>
    <div class="cards-list-container justify-content-center">
    @for (licence of company.vehicle_operator_licences; track $index) {
    <div class="zint-base-card vehicle-licence-card">
      <div *ngIf="licence.vehicles_specified >= 1 || licence.number_of_vehicles_authorised >= 1" class="row">
        <h6 class="col-4">Vehicles</h6>
        <div class="col-8">
          <span class="mr-2">Specified:</span><span>{{licence.vehicles_specified}}</span><br>
          <span class="mr-2">Authorised:</span><span>{{licence.number_of_vehicles_authorised}}</span>
        </div>
      </div>
      <div *ngIf="licence.trailers_specified >= 1 || licence.number_of_trailers_authorised >= 1" class="row">
        <h6 class="col-4">Trailers</h6>
        <div class="col-8">
          <span class="mr-2">Specified:</span><span>{{licence.trailers_specified}}</span><br>
          <span class="mr-2">Authorised:</span><span>{{licence.number_of_trailers_authorised}}</span>
        </div>
      </div>
      <div class="row" *ngIf="licence.licence_status">
        <h6 class="col-4">Status</h6>        
        <span class="col-8">{{getVehicleLicenceStatus(licence.licence_status) | titlecase}}</span>        
      </div>
      <div class="row" *ngIf="licence.licence_type">
        <h6 class="col-4">Type</h6>        
        <span class="col-8">{{licence.licence_type}}</span>        
      </div>
      <div class="row" *ngIf="licence.continuation_date">
        <h6 class="col-4">Continuation Date</h6>        
        <span class="col-8">{{licence.continuation_date | date: 'd MMM y'}}</span>        
      </div>
      <div class="row" *ngIf="licence.oc_address">
        <h6 class="col-4">Addresses</h6>
        <span class="col-8">{{formatVehicleLicenceAddresses(licence.oc_address)}}</span>        
      </div>                   
      
    </div>
  }
    </div>

  </mat-expansion-panel>
}

  @if (pubMethods.hasData(company.latest_news)) {
    <mat-expansion-panel id="latest_news_section" [style.order]="getSectionOrder('latest_news')" hideToggle (expandedChange)="setSectionExpandedState('latest_news_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('latest_news')"></i>
            Latest News
            <sup class="section-counter-badge">
              {{navSectionPolledCount['latest_news']}}
            </sup>
            <i *ngIf="expandedSections['latest_news_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </mat-panel-title>        
      </mat-expansion-panel-header>
      <div class="news-container row" 
      [ngClass]="{'justify-content-center': !company.latest_news.news?.length && !company.latest_news.self_published_news?.length}">  
        @if (company.latest_news.news?.length || company.latest_news.self_published_news?.length) {     
          <div class="col-12" [ngClass]="{'col-lg-9': company.latest_news.twitter_handle}">
            <news-carousel [latestNews]="company.latest_news" [companyNumber]="companyNumber" [isSmallScreen]="isSmallScreen" [newsSearchTerm]="thirdPartyNewsSearchText" [selfPubNewsSearchTerm]="selfPublishedCompanyNewsSearchText"></news-carousel>
          </div>
        }
        @if (company.latest_news.twitter_handle) {
          <div class="col-12 col-lg-3 twitter-container">           
              <h5>X (Twitter) Posts</h5>
              <a class="twitter-timeline" data-dnt="true" data-theme="light"
              data-tweet-limit="10"
              href="https://twitter.com/{{company.latest_news.twitter_handle}}?ref_src=twsrc%5Etfw"
              target="_blank">
                &commat;{{company.latest_news.twitter_handle}}
              </a>
          </div>
        }
        
      </div>
    </mat-expansion-panel>
  }

  @if (jobsListToRender.length) {
    <mat-expansion-panel id="hiring_for_section" [style.order]="getSectionOrder('hiring_for')" hideToggle (expandedChange)="setSectionExpandedState('hiring_for_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('hiring_for')"></i>
            Hiring For
            <sup class="section-counter-badge">
              {{navSectionPolledCount['hiring_for']}}
            </sup>
            <i *ngIf="expandedSections['hiring_for_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="">
        <div class="search-reset-container">
          <button class="rounded-btn-base rounded-btn-light btn-p-xs" (click)="resetSearch('jobSearch')">
            <i class="fal fa-undo"></i>
          </button>
          <div class="search-input">
            <i class="fal fa-search"></i>
            <input type="text" placeholder="Search jobs"
            [(ngModel)]="jobSearchTerm"              
            (keyup.enter)="performJobSearch($event.target.value)">
          </div>
        </div>
         
        <div class="cards-list-container">
          @if (loadingStatus['loadingJobSearch'] === true) {
            <div class="mx-auto mt-2" style="height: 4rem;">
              <zint-loader></zint-loader>
            </div>
          }
          @else {
            @for (jobObj of jobsListToRender; track $index) { 
            
            <div class="job-card">
                @if (jobObj.link) {
                  <h5 class="mb-4">
                    <a [href]="jobObj.link" target="_blank">{{jobObj.title}}<i class="fal fa-external-link fa-xs ml-1"></i></a>
                  </h5>
                }
                @if (jobObj.summary) {
                  <p [innerHTML]="jobObj.summary"></p>
                }
                @else {
                  <p>Please follow the link to read job description on the website.</p>
                }
                @if (jobObj.date_published) {
                  <p class="mb-0">{{jobObj.date_published}}</p>
                }
              
            </div> 
          }
        }
          </div>

    </div>

  </mat-expansion-panel>
}

  @if(company.events?.length) {
    <mat-expansion-panel id="events_attending_section" [style.order]="getSectionOrder('events_attending')" hideToggle (expandedChange)="setSectionExpandedState('events_attending_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('events_attending')"></i>
            Events Attending
            <sup class="section-counter-badge">
              {{navSectionPolledCount['events_attending']}}
            </sup>
            <i *ngIf="expandedSections['events_attending_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div id="eventsCarousel" class="carousel slide custom-carousel events-carousel" style="height: 20rem;" data-ride="carousel" data-interval="false">
      
        <div *ngIf="company.events.length > 3" class="controls-wrapper">
          <a class="custom-control-left" href="#eventsCarousel" data-slide="prev">
            <i role="button" class="fas fa-caret-circle-left"></i>
          </a>
          <ol class="carousel-indicators">
            <li *ngFor="let chunk of chunkForCarousel(company.events); let i = index" [class.active]="i === 0"
              data-target="#eventsCarousel" [attr.data-slide-to]="i">
            </li>
            
          </ol>
          <a class="custom-control-right" href="#eventsCarousel" data-slide="next">
            <i role="button" class="fas fa-caret-circle-right"></i>
          </a>
        </div>

        <div class="carousel-inner">
          @for (chunk of chunkForCarousel(company.events); track $index) {
            
          <div class="carousel-item" [ngClass]="{'active': company.events.indexOf(chunk[0]) === 0}">
            <div class="event-list-container">
            @for (event of chunk; track $index) {
              
            <div class="event-carousel-card">
              <h5>
                <a [href]="event.event_url" target="_blank">{{event.name}}</a>
              </h5>
              <span>{{event.start | date: 'd MMM yyyy'}} - {{event.end | date: 'd MMM yyyy'}}</span>
              <span><em>{{event.description}}</em></span>            
              <a [href]="event.exhibitor_link" target="_blank">View Exhibitor Page</a>
            </div>
              
          }
            </div>
          </div>
        }
        </div>
      </div>
    </mat-expansion-panel>
  }

  @if (pubMethods.hasData(company['imports_&_exports'])) {
    <mat-expansion-panel id="imports_&_exports_section" [style.order]="getSectionOrder('imports_&_exports')" hideToggle (expandedChange)="setSectionExpandedState('imports_&_exports_section'); getCompanyDetailedImportsExportsData()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('imports_&_exports')"></i>
            Imports / Exports
            <sup class="section-counter-badge">
              {{navSectionPolledCount['imports_&_exports']}}
            </sup>
            <i *ngIf="expandedSections['imports_&_exports_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </mat-panel-title>        
      </mat-expansion-panel-header>
      
      <div class="row max-height-50vh">
        
        @for (KV of company['imports_&_exports'] | keyvalue: originalOrder; track $index) {
      
        <div class="col col-md-6 mb-3 mb-md-1"> 
          <div class="chart-card text-grey-600">
            <div class="d-flex justify-content-between align-items-baseline m-3">
              <h5>{{KV.key | titlecase}}:</h5>         
              <span role="button" data-toggle="modal" data-target="#impExpModal"
                  (click)="impExpModalType = KV.key">View all {{KV.key}}</span>
            </div>
            <ngx-charts-bar-vertical                        
            [results]='KV.value' 
            [scheme]="impExpChartColorScheme[KV.key]" 
            [gradient]="false"
            [schemeType]="'linear'"
            [xAxis]="true" [yAxis]="true" 
            [legend]="false" 
            [showXAxisLabel]="false" [showYAxisLabel]="true"           
            [yAxisLabel]="'Number of ' +KV.key">
            </ngx-charts-bar-vertical>
          </div>
        </div>
        }
        
      </div>      
    </mat-expansion-panel>
  }
    
  @if (company.share_allotments?.length || companyShareholdersOf?.length || companiesShareholderIn?.length) {
    <mat-expansion-panel id="share_information_section" [style.order]="getSectionOrder('share_information')" hideToggle (expandedChange)="setSectionExpandedState('share_information_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('share_information')"></i>
            Share Information
            <sup class="section-counter-badge">
              {{navSectionPolledCount['share_information']}}
            </sup>
            <i *ngIf="expandedSections['share_information_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </mat-panel-title>
      </mat-expansion-panel-header>

      @if (company.share_allotments?.length) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>
                Share Filings
              </h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="financials-table-container">
            <table class="table financials-table table-layout-fixed">
              <thead>
                <tr class="header-row">
                  <th scope="col" class="">Filing Type</th>
                  <th scope="col" class="">Allotment Date</th>
                  <th scope="col" class="">Document link</th>
                  <th scope="col" class="">Capital Values</th>
                </tr>
              </thead>

              <tbody>
                @for (item of company.share_allotments; track $index) {
                <tr>
                  <td>{{item.type}}</td>
                  <td>{{item.date}}</td>
                  <td>
                    <a [href]="item.pdf_url" target="_blank">
                      View online
                      <i class="fal fa-external-link fa-sm"></i>
                  </a></td>
                  <td>
                    @if (item.capital_values?.length) {
                      @for (capitalVal of item?.capital_values; track $index) {
                        <span>
                          {{capitalVal.figure | number}}
                          &nbsp;{{capitalVal.currency}}
                        </span>
                        }
                      }
                      @else {
                        &#65343;&#65343;&#65343;&#65343;
                      }
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </mat-expansion-panel>
      }

      @if (companyShareholdersOf?.length) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>
                Shareholders
              </h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="financials-table-container">
            <table class="table financials-table table-layout-fixed">
              <thead>
                <tr class="header-row">
                  <th scope="col" class="">Shareholder</th>
                </tr>
              </thead>

              <tbody>
                @for (item of companyShareholdersOf; track $index) {
                <tr>
                  <td><a href="/profile/{{item.company_number}}" target="_blank" class="d-inline-block">{{item.company_name}}</a></td>
                </tr>
                }
              </tbody>
            </table>
          </div>

        </mat-expansion-panel>

      }

      @if (companiesShareholderIn?.length) {
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h5>
                Share Holdings
              </h5>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="financials-table-container">
            <table class="table financials-table table-layout-fixed">
              <thead>
                <tr class="header-row">
                  <th scope="col" class="">Portfolio Company</th>
                </tr>
              </thead>

              <tbody>
                @for (item of companiesShareholderIn; track $index) {
                <tr>
                  <td><a href="/profile/{{item.company_number}}" target="_blank" class="d-inline-block">{{item.company_name}}</a></td>
                </tr>
                }
              </tbody>
            </table>
          </div>

        </mat-expansion-panel>

      }

    </mat-expansion-panel>
  }

  @if (pubMethods.hasData(company.confirmation_statements)) {
  <mat-expansion-panel id="shareholders_section" [style.order]="getSectionOrder('shareholders')" hideToggle
    (expandedChange)="setSectionExpandedState('shareholders_section')" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('shareholders')"></i>
          Shareholders
          <sup class="section-counter-badge">
            {{navSectionPolledCount['shareholders']}}
          </sup>
          <span class="status-badge badge-beta">BETA</span>
          <i *ngIf="expandedSections['shareholders_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>

    @if (confirmedShareholdersData.length > 1) {
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="handleConfirmationStatementsTabChange($event)">
      @for (update of confirmedShareholdersData; track $index) {
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="text-bold-600">{{update.lastUpdated | date: 'dd MMM y'}}</span>
        </ng-template>
        <!-- matTabContent means lazy-load, needed for the donut chart https://v17.material.angular.io/components/tabs/overview#lazy-loading -->
        <ng-template matTabContent>
          <div class="shareholders-data-container">
            <div class="donut-chart-container">
              <donut-chart *ngIf="update.seriesTotal > 0" [donutData]="update"></donut-chart>
              <div class="donut-data-cards-container">
                <div class="zint-base-card zint-light-card donut-data-card">
                  <h6>Last Updated</h6>
                  <span>{{update.lastUpdated | date: 'dd MMM y'}}</span>
                </div>
                <div class="zint-base-card zint-light-card donut-data-card">
                  <h6>Total Number of Shares</h6>
                  <span>{{update.seriesTotal > 0 ? update.seriesTotal : 'Unknown'}}</span>
                </div>
              </div>
            </div>
            <div class="shareholders-table-container">              
              <backend-table [tableData]="company.confirmation_statements[confirmationStatementsTabIndex].shareholders_table_data" [breakRowHeaders]="false"
                [columnHeaderCentered]="true" [tableHeight]="'40vh'"></backend-table>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      }
    </mat-tab-group>
    }
    @else {
    <div class="shareholders-data-container">
      <div class="donut-chart-container ">
        <donut-chart *ngIf="confirmedShareholdersData[0]?.seriesTotal > 0" [donutData]="confirmedShareholdersData[0]"></donut-chart>
        <div class="donut-data-cards-container">
          <div class="zint-base-card zint-light-card donut-data-card">
            <h6>Last Updated</h6>
            <span>{{confirmedShareholdersData[0].lastUpdated | date: 'dd MMM y'}}</span>
          </div>
          <div class="zint-base-card zint-light-card donut-data-card">
            <h6>Total Number of Shares</h6>
            <span>{{confirmedShareholdersData[0].seriesTotal > 0 ? confirmedShareholdersData[0].seriesTotal : 'Unknown'}}</span>
          </div>
        </div>
      </div>

      <div class="shareholders-table-container">
        <backend-table [tableData]="company.confirmation_statements[0].shareholders_table_data" [breakRowHeaders]="false" [columnHeaderCentered]="true" [tableHeight]="'40vh'"></backend-table>
      </div>
    </div>
    }
  
    </mat-expansion-panel>
  }

  @if (company.grants?.length) {
    <mat-expansion-panel id="grants_section" [style.order]="getSectionOrder('grants')" hideToggle
      (expandedChange)="setSectionExpandedState('grants_section')">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>
            <i class="mr-1" [ngClass]="getSectionIcon('grants')"></i>
            Grants
            <sup class="section-counter-badge">
              {{navSectionPolledCount['grants']}}
            </sup>
            <i *ngIf="expandedSections['grants_section']" class="ml-1 far fa-angle-up"></i>
          </h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="cards-list-container">
        @for (grant of company.grants; track $index) {
          <div class="profile-element-card">
            <h5>{{grant.project_title}}</h5>
            @if (grant.description) {              
            <truncate-text [inputText]="grant.description"></truncate-text>
            }
            <div class="d-flex my-3 flex-wrap">
              @if (grant.project_start_date && grant.project_end_date) {
              <span class="badge-light-grey mr-2 mb-2">{{ grant.project_start_date |
              date:'d MMM y' }} - {{ grant.project_end_date | date:'d MMM y'}}
              </span>
              }
              @if (grant.grant_offered) {
                <span class="badge-light-grey mr-2 mb-2">
                Grant Offered: {{ grant.grant_offered |
                currency:'GBP':'symbol-narrow':'.0'}}
                </span>
              }
              @if (grant.total_costs) {
                <span class="badge-light-grey mr-2 mb-2">
                  Total Project Cost: {{ grant.total_costs |
                  currency:'GBP':'symbol-narrow':'.0'}}
                </span>
              }
              @if (grant.spend_to_date) {
                <span class="badge-light-grey mr-2 mb-2">Spend to Date: {{ grant.spend_to_date |
                  currency:'GBP':'symbol-narrow':'.0'}}
                </span>
              }
            </div>
          </div>
        }
      </div>
    </mat-expansion-panel>
  }

  @if (company.recent_legislation?.length) {
  <mat-expansion-panel id="recent_legislation_section" [style.order]="getSectionOrder('recent_legislation')" hideToggle (expandedChange)="setSectionExpandedState('recent_legislation_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('recent_legislation')"></i>
          Recent Legislation
          <sup class="section-counter-badge">
            {{navSectionPolledCount['recent_legislation']}}
          </sup>
          <i *ngIf="expandedSections['recent_legislation_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="cards-list-container">
      @for(legislation of company.recent_legislation; track $index){
        <div class="profile-element-card flex-grow-48">
          <a [href]="legislation.link" target="_blank">
            <h5 matTooltip="Read online">{{legislation.title}}
              <i class="fal fa-external-link fa-xs ml-1 text-vivid-blue"></i>
            </h5>            
          </a>
          <span class="badge-light-grey mb-2">Status: {{legislation.type}}</span>
          
          <truncate-text [inputText]="legislation.summary"></truncate-text>
        </div>
      }
    </div>
  </mat-expansion-panel>
}

@if (pubMethods.hasData(company?.legal_entity_identifier)) {
  <mat-expansion-panel id="legal_entity_identifier_section" [style.order]="getSectionOrder('legal_entity_identifier')" hideToggle
    (expandedChange)="setSectionExpandedState('legal_entity_identifier_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('legal_entity_identifier')"></i>
          Legal Entity Identifier
          <sup class="section-counter-badge">
            {{navSectionPolledCount['legal_entity_identifier']}}
          </sup>
          <i *ngIf="expandedSections['legal_entity_identifier_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
      
        <div class="law-card">
          <a href="https://www.gleif.org/lei/{{ company.legal_entity_identifier.lei_number }}" target="_blank">
            <h5 class="d-inline-block" matTooltip="Read more on LEI website " matTooltipPosition="after">LEI Number: {{company.legal_entity_identifier.lei_number}}
              <i class="fal fa-external-link fa-xs text-vivid-blue"></i>
            </h5>
          </a>
          <p>Registration Status: {{company.legal_entity_identifier.registration_status}}</p>
          <p>Validation Sources: {{company.legal_entity_identifier.registration_validation_sources}}</p>
          <p>Initial Registration Date: {{company.legal_entity_identifier.initial_registration_date | date:'d MMM y'}}</p>
          <p>Renewal Date: {{company.legal_entity_identifier.renewal_date | date:'d MMM y'}}</p>
          <details class="">
            <summary class="text-vivid-blue">What is an LEI?</summary>
            <span>A Legal Entity Identifier (LEI) is a 20-character identifier that identifies distinct legal entities that
              engage in
              financial transactions. It is defined by ISO 17442.</span>
          </details>
        </div>

  </mat-expansion-panel>
}

  @if (permissionsCheckMap.patents_filed) {
  <mat-expansion-panel id="patents_filed_section" [style.order]="getSectionOrder('patents_filed')" hideToggle
    (expandedChange)="setSectionExpandedState('patents_filed_section')" [disabled]="navSectionPolledCount['patents_filed'] < 1">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('patents_filed')"></i>
          Patents Filed
          <sup class="section-counter-badge">
            {{navSectionPolledCount['patents_filed']}}
          </sup>
          <i *ngIf="expandedSections['patents_filed_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="financials-table-container">
      @if (!company.patents_filed?.length) {
        <span>No patent data yet.</span>
      }
      @else {
        <table class="table financials-table table-layout-fixed">
          <thead>
            <tr class="header-row">
              <th scope="col" class="">Title</th>
              <th scope="col" class="">Status</th>
              <th scope="col" class="">Application Number</th>
              <th scope="col" class="">Date Filed</th>
              <th scope="col" class="">First Applicant</th>            
            </tr>
          </thead>
        
          <tbody>
            @for (patent of company.patents_filed; track $index) {
              <tr>
                <td>{{patent.title}}</td>
                <td>{{patent.status}}</td>
                <td>
                  <a [href]="patent.ipo_link" target="_blank">{{patent.application_number}}
                    <i class="fal fa-external-link fa-xs ml-1"></i>
                  </a>
                </td>
                <td>{{patent.date_filed}}</td>
                <td>
                  @for (itemKV of patent.first_applicant | keyvalue:originalOrder; track $index) {
                    <span *ngIf="itemKV.key !== 'adp_number'">{{itemKV.value}}&nbsp;</span>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
  </mat-expansion-panel>
}

  @if (company.trademarks?.length) {
  <mat-expansion-panel id="trademarks_section" [style.order]="getSectionOrder('trademarks')" hideToggle
    (expandedChange)="setSectionExpandedState('trademarks_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('trademarks')"></i>
          Trademarks
          <sup class="section-counter-badge">
            {{navSectionPolledCount['trademarks']}}
          </sup>
          <i *ngIf="expandedSections['trademarks_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="financials-table-container">
      <table class="table financials-table table-layout-fixed">
        <thead>
          <tr class="header-row">
            <th scope="col" class="">TM Number</th>
            <th scope="col" class="">Text</th>
            <th scope="col" class="">Status</th>
            <th scope="col" class="">TM Type</th>
            <th scope="col" class="">Date Filed</th>            
            <th scope="col" class="">Date Published</th>            
            <th scope="col" class="">Classification</th>            
          </tr>
        </thead>
      
        <tbody>
          @for (trademark of company.trademarks; track $index) {
            <tr>
              <td>
                <a [href]="trademark.url" target="_blank" class="text-break">{{trademark.trademark_number}}
                  <i class="fal fa-external-link fa-xs ml-1"></i>
                </a>
              </td>
              <td>{{trademark.trademark_text}}</td>
              <td>{{trademark.status}}</td>
              <td>{{trademark.type_of_trademark}}</td>
              <td>{{trademark.date_filed}}</td>
              <td>{{trademark.date_published}}</td>
              <td>
                @for (class of trademark.classes; track $index;) {
                  @if ($index > 0) {
                    <span>,&nbsp;{{class}}</span>
                  } @else {
                  <span>{{class}}</span>
                  }
                }
              </td>
              
            </tr>
          }
        </tbody>
      </table>
    </div>
  </mat-expansion-panel>
}

  @if (governmentContractsToRender && governmentContractsToRender.length) {
  <mat-expansion-panel id="government_contracts_awarded_section" [style.order]="getSectionOrder('government_contracts_awarded')" hideToggle
    (expandedChange)="setSectionExpandedState('government_contracts_awarded_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('government_contracts_awarded')"></i>
          Government Contracts Awarded
          <sup class="section-counter-badge">            
            {{navSectionPolledCount['government_contracts_awarded']}}
          </sup>
          <i *ngIf="expandedSections['government_contracts_awarded_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
      <button class="rounded-btn-base rounded-btn-light text-grey-600" (click)="confirmDownloadAllGovernmentContracts(); $event.stopPropagation();" matTooltip="&midast;&nbsp;Uses your download quota.">Download All Contracts<sup>*</sup></button>
    </mat-expansion-panel-header>
    <div class="financials-table-container d-flex flex-column">      
      <table class="table financials-table table-layout-fixed">
        <thead>
          <tr class="header-row">
            <th scope="col" class="">Title</th>
            <th scope="col" class="">Provided to</th>
            <th scope="col" class="">Description</th>
            <th scope="col" class="">Date Awarded</th>
            <th scope="col" class="">Contract Value</th>
            <th scope="col" class="">Contract Period</th>
            <th scope="col" class="">Further Details</th>                                 
          </tr>
        </thead>
      
        <tbody>
          @for (contract of governmentContractsToRender; track $index;) {
            <tr>
              <td>{{contract.title}}</td>
              <td>{{contract.provided_to}}</td>
              <td>
                <truncate-text [inputText]="contract.description" [limit]="13"></truncate-text>
              </td>
              <td>{{contract.date_awarded}}</td>
              <td>{{contract.contract_value | companyDataPipe:'Revenue': null}}</td>
              <td>{{contract.start | date: 'dd MMM yyyy'}} - {{contract.end | date: 'dd MMM yyyy'}}</td>
              <td>
                <a [href]="'https://www.contractsfinder.service.gov.uk/notice/'+ contract.guid" target="_blank" matTooltip="on Contracts Finder">More details
                  <i class="fal fa-external-link fa-xs ml-1"></i>
                </a>
              </td>            
              
            </tr>
          }
        </tbody>
      </table>
      @if (governmentContractsToRender.length === 20) {        
          <button class="rounded-btn-base rounded-btn-vivid-outline btn-p-lg align-self-center mt-3" (click)="getAllGovernmentContracts(true)">
            View All Contracts <zint-loader *ngIf="loadingStatus['loadingGovernmentContracts']" size="xxSmall" [isInline]="true"></zint-loader>
          </button>        
      }
    </div>
  </mat-expansion-panel>
}

@if(pubMethods.hasData(company.online_marketplaces)){
  <mat-expansion-panel id="online_marketplaces_section" [style.order]="getSectionOrder('online_marketplaces')" hideToggle
    (expandedChange)="setSectionExpandedState('online_marketplaces_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('online_marketplaces')"></i>
          Online marketplaces
          <sup class="section-counter-badge">
            {{navSectionPolledCount['online_marketplaces']}}
          </sup>
          <i *ngIf="expandedSections['online_marketplaces_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    
    @if(company.online_marketplaces.gcloud?.length) {
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5 class="mb-0"><i class="fal fa-server mr-1"></i>G Cloud</h5>
          </mat-panel-title>
        </mat-expansion-panel-header>        

        <div class="cards-list-container max-height-25vh">
          @for (item of company.online_marketplaces.gcloud; track $index) {
            <div class="job-card">
              <span>Listed on the G Cloud with pricing for their product detailed at "{{item.price}}"</span><br>
              <a [href]="item.link" target="_blank">View on G Cloud <i class="fal fa-external-link fa-xs"></i></a><br>
              <a [href]="item.t_and_c" target="_blank">Read Full T&Cs <i class="fal fa-external-link fa-xs"></i></a>              
            </div>
          }
        </div>        
      </mat-expansion-panel>
    }
    @if(company.online_marketplaces.amazon_seller?.length) {
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h5 class="mb-0"><i class="fab fa-amazon mr-1" style="vertical-align: bottom;"></i>amazon.co.uk</h5>
          </mat-panel-title>
        </mat-expansion-panel-header>        

        <div class="cards-list-container">
          @for (item of company.online_marketplaces.amazon_seller; track $index) {
            <div class="job-card">
              <span>Sells products on Amazon under the trade name "{{item.seller_name}}"</span><br>
              <a [href]="item.url" target="_blank">Find our more on Amazon <i class="fal fa-external-link fa-xs"></i></a><br>              
            </div>
          }
        </div>

        
      </mat-expansion-panel>
    }
  </mat-expansion-panel>

}

@if (company.company_charges?.length) {
<mat-expansion-panel id="company_charges_section" [style.order]="getSectionOrder('company_charges')" hideToggle
  (expandedChange)="setSectionExpandedState('company_charges_section')">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4>
        <i class="mr-1" [ngClass]="getSectionIcon('company_charges')"></i>
        Company Charges
        <sup class="section-counter-badge">
          {{navSectionPolledCount['company_charges']}}
        </sup>
        <i *ngIf="expandedSections['company_charges_section']" class="ml-1 far fa-angle-up"></i>
      </h4>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="financials-table-container">
    <table class="table financials-table table-layout-fixed">
      <thead>
        <tr class="header-row">
          <th scope="col" class="">Classification</th>
          <th scope="col" class="">Persons Entitled</th>
          <th scope="col" class="">Status</th>
          <th scope="col" class="">Charge Identifier</th>
          <th scope="col" class="">Created</th>
          <th scope="col" class="">Link</th>
        </tr>
      </thead>

      <tbody>
        @for (charge of company.company_charges; track $index) {
        <tr>
          <td>{{charge.classification_description}}</td>

          <td>
          @if (charge.persons_entitled?.length) {
            @for (person of charge.persons_entitled; track $index) {
              @if (person.company_number) {
                <a href="/profile/{{person.company_number}}" target="_blank" class="d-inline-block">{{person.name}}</a>
              }
              @else {
                <span class="d-inline-block">{{person.name}}</span>
              }
            }
          }
          @else {
            <span>&#65343;&#65343;&#65343;&#65343;</span>
          }
        </td>  
        <td>{{charge.status}}</td>        
        <td>{{charge.code || '&#65343;&#65343;&#65343;&#65343;'}}</td>        
        <td>{{charge.created_on | date: 'dd MMM yyyy'}}</td>        
          <td>
            <a href="https://find-and-update.company-information.service.gov.uk/company/{{companyNumber}}/filing-history/{{ charge.creation_link_id }}/document?format=pdf&download=0"
              target="_blank" target="_blank" matTooltip="on Companies House">More details
              <i class="fal fa-external-link fa-xs ml-1"></i>
            </a>
          </td>

        </tr>
        }
      </tbody>
    </table>
  </div>
</mat-expansion-panel>
}

@if(permissionsCheckMap.energy_and_emissions){
<mat-expansion-panel id="energy_and_emissions_section" [style.order]="getSectionOrder('energy_and_emissions')" hideToggle
  (expandedChange)="setSectionExpandedState('energy_and_emissions_section')" [disabled]="navSectionPolledCount['energy_and_emissions'] < 1">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4>
        <i class="mr-1" [ngClass]="getSectionIcon('energy_and_emissions')"></i>
        Energy and Emissions
        <sup class="section-counter-badge">
          {{navSectionPolledCount['energy_and_emissions']}}
        </sup>
        <i *ngIf="expandedSections['energy_and_emissions_section']" class="ml-1 far fa-angle-up"></i>
      </h4>
    </mat-panel-title>
  </mat-expansion-panel-header>
  @if (!company.energy_and_emissions?.energy?.length && !company.energy_and_emissions?.emissions?.length) {
    <span>No energy and emissions data yet.</span>
  }
  @else {
    @if(company.energy_and_emissions.energy?.length) {
    <mat-expansion-panel togglePosition="before">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5 class="mb-0"><i class="fal fa-outlet mr-1"></i>Energy</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="financials-table-container">
        @if (hasLowEnergyUse(company.energy_and_emissions.energy)) {
        <h5 class="mb-0">Reported low energy usage.</h5>
        }
        @else {      
        <table class="table financials-table table-layout-fixed">
          <thead>
            <tr class="header-row">
              <th scope="col" class="sticky-col">Type</th>
              @for (item of company.energy_and_emissions.energy; track $index) {
                <th scope="col" class="">Y.E. {{item.made_up_to | date: 'd MMM y'}}</th>
              }            
            </tr>
          </thead>    
          <tbody>
            @for (item of availableEnergyTerms; track $index) {
              <tr>
                <th scope="row" class="sticky-col">{{item | underscoreToUpper}}</th>

                @for (energyObj of company.energy_and_emissions.energy; track $index) {
                  @if (energyObj[item] === null) {
                    <td>&#65343;&#65343;&#65343;&#65343;</td>
                  }
                  @else {
                    <td>{{energyObj[item] | companyDataPipe: 'Total Energy Usage'}}</td>
                  }
                }
              </tr>
            }
          </tbody>
        </table>
      }
      </div>
      
    </mat-expansion-panel>
    }
    @if(company.energy_and_emissions.emissions?.length) {
    <mat-expansion-panel togglePosition="before">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h5 class="mb-0"><i class="fal fa-campfire mr-1"></i>Emissions</h5>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <div class="financials-table-container">
        <table class="table financials-table table-layout-fixed">
          <thead>
            <tr class="header-row">
              <th scope="col" class="sticky-col">Type</th>
              @for (item of company.energy_and_emissions.emissions; track $index) {
              <th scope="col" class="">Y.E. {{item.made_up_to | date: 'd MMM y'}}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (item of availableEmissionsTerms; track $index) {
            <tr>
              <th scope="row" class="sticky-col">
                {{item | underscoreToUpper}}              
              </th>
        
              @for (energyObj of company.energy_and_emissions.emissions; track $index) {
              @if (energyObj[item] === null) {
              <td>&#65343;&#65343;&#65343;&#65343;</td>
              }
              @else {
              <td>{{energyObj[item] | companyDataPipe: 'Total Emissions'}}</td>
              }
              }
            </tr>
            }
          </tbody>
        </table>
      </div>

    </mat-expansion-panel>
    }
  }
</mat-expansion-panel>

}

@if (permissionsCheckMap.energy_and_emissions) {
<mat-expansion-panel id="sustainability_section" [style.order]="getSectionOrder('sustainability')" hideToggle
  (expandedChange)="setSectionExpandedState('sustainability_section')" class="mb-5" [disabled]="navSectionPolledCount['sustainability'] < 1">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4>
        <i class="mr-1" [ngClass]="getSectionIcon('sustainability')"></i>
        Sustainability Data
        <sup class="section-counter-badge">
          {{navSectionPolledCount['sustainability']}}
        </sup>
        <span class="status-badge badge-beta">BETA</span>
        <i *ngIf="expandedSections['sustainability_section']" class="ml-1 far fa-angle-up"></i>
      </h4>
    </mat-panel-title>
  </mat-expansion-panel-header>

  @if (!pubMethods.hasData(llMEnergyEmissionsData.Energy) && !pubMethods.hasData(llMEnergyEmissionsData.Emissions)) {
    <span>No data yet.</span>
  }

  @if (pubMethods.hasData(llMEnergyEmissionsData.Energy)) {
  <mat-expansion-panel togglePosition="before">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5 class="mb-0">
          Energy
        </h5>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <backend-table [tableData]="llMEnergyEmissionsData.Energy" [formatting]="'Total Energy Usage'"></backend-table>
  </mat-expansion-panel>
  }

  @if (pubMethods.hasData(llMEnergyEmissionsData.Emissions)) {
  <mat-expansion-panel togglePosition="before">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h5 class="mb-0">
          Emissions
        </h5>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <backend-table [tableData]="llMEnergyEmissionsData.Emissions" [formatting]="'Total Emissions'"></backend-table>
  </mat-expansion-panel>
  }

</mat-expansion-panel>
}


@if (company.functional_departments?.length) {
<mat-expansion-panel id="functional_departments_section" [style.order]="getSectionOrder('functional_departments')" hideToggle
  (expandedChange)="setSectionExpandedState('functional_departments_section')">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4>
        <i class="mr-1" [ngClass]="getSectionIcon('functional_departments')"></i>
        Functional Departments
        <sup class="section-counter-badge">
          {{navSectionPolledCount['functional_departments']}}
        </sup>
        <i *ngIf="expandedSections['functional_departments_section']" class="ml-1 far fa-angle-up"></i>
      </h4>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="financials-table-container">
    <table class="table financials-table">
      <thead>
        <tr class="header-row">
          @for (dept of company.functional_departments; track $index) {
          <th scope="col" class="">{{dept.dept}}</th>
          }          
        </tr>
      </thead>

      <tbody>
        <tr>
        @for (dept of company.functional_departments; track $index) {          
          <td>{{dept.total}}</td>
        }
      </tr>
      </tbody>
    </table>
  </div>
</mat-expansion-panel>
}


<mat-expansion-panel id="corporate_structure_section" [style.order]="getSectionOrder('corporate_structure')" hideToggle
  (expandedChange)="setSectionExpandedState('corporate_structure_section'); renderStructureGraphs = true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4 #corpGraphDeferRef>
        <i class="mr-1" [ngClass]="getSectionIcon('corporate_structure')"></i>
        Corporate Structure
        <i *ngIf="expandedSections['corporate_structure_section']" class="ml-1 far fa-angle-up"></i>
      </h4>
    </mat-panel-title>
    @if (renderStructureGraphs && companyNumber){
    @if (showClusterNotStructure){
    <button class="rounded-btn-base rounded-btn-light text-grey-600  mr-2"
      matTooltip="View the entire corporate structure of this company"
      (click)="showClusterNotStructure = false; $event.stopPropagation();">View Corporate Structure</button>
      <button class="rounded-btn-base rounded-btn-light" matTooltip="Opens in a new page"
        (click)="$event.stopPropagation()">
        <a class="text-grey-600 btn-link-no-underline" target="_blank"
          href="/profile/{{companyNumber}}/clusters">View All Clusters</a>
      </button>
    }
    @else {
    <button class="rounded-btn-base rounded-btn-light text-grey-600 mr-2"
      matTooltip="View the immediate group of this company"
      (click)="showClusterNotStructure = true; $event.stopPropagation();">View Cluster</button>
      <button class="rounded-btn-base rounded-btn-light"
        matTooltip="Opens in the Dashboard" (click)="$event.stopPropagation()">
        <a class="text-grey-600 btn-link-no-underline" target="_blank" href="/dashboard?corporateStructureRelatedCompanies={{companyNumber}}">View As List</a>
      </button>
    }
    }
  </mat-expansion-panel-header>
  @defer(on viewport(corpGraphDeferRef)) {
    @if (renderStructureGraphs) {
      <div class="ngx-graph-holder">
        @if (showClusterNotStructure){
        <app-company-clusters [clustersGraphType]="'immediate'"></app-company-clusters>
        }
        @else {
        <corporate-structure-graph [companyNumber]="companyNumber"
          [companyName]="company.company_overview?.name"></corporate-structure-graph>
        }
      </div>
    }
  }

</mat-expansion-panel>

<mat-expansion-panel id="cross_sell_opportunities_section" [style.order]="getSectionOrder('cross_sell_opportunities')" hideToggle
  (expandedChange)="setSectionExpandedState('cross_sell_opportunities_section'); ">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h4 #xSellGraphDeferRef>
        <i class="mr-1" [ngClass]="getSectionIcon('cross_sell_opportunities')"></i>
        Cross Sell Opportunities
        <i *ngIf="expandedSections['cross_sell_opportunities_section']" class="ml-1 far fa-angle-up"></i>
      </h4>
    </mat-panel-title>
    <button *ngIf="expandedSections['cross_sell_opportunities_section']" class="rounded-btn-base rounded-btn-light" matTooltip="Open in a new page" (click)="$event.stopPropagation()">
      <a href="/profile/{{companyNumber}}/cross-sell" target="_blank" class="text-grey-600 btn-link-no-underline">
        <i class="fal fa-external-link"></i>
      </a>
    </button>
  </mat-expansion-panel-header>

  @defer (on viewport(xSellGraphDeferRef)) {
    @if (expandedSections['cross_sell_opportunities_section']) {
      <div class="ngx-graph-holder">
        <cross-sell-graph [companyNumber]="companyNumber"></cross-sell-graph>
      </div>
    }
  }
 

</mat-expansion-panel>

@if (permissionsCheckMap.probable_suppliers) {
  <mat-expansion-panel id="probable_suppliers_section" [style.order]="getSectionOrder('probable_suppliers')" hideToggle
    (expandedChange)="setSectionExpandedState('probable_suppliers_section')" [disabled]="navSectionPolledCount['probable_suppliers'] < 1">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('probable_suppliers')"></i>
          Probable Suppliers
          <sup class="section-counter-badge">
            {{navSectionPolledCount['probable_suppliers']}}
          </sup>
          <i *ngIf="expandedSections['probable_suppliers_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    
    
    <div class="financials-table-container">
        @if (!supplierData?.length) {
          <h6>No data yet</h6>
        }@else {
        <table class="table financials-table">
          <thead>
            <tr class="header-row">              
                <th scope="col">Company</th>
                <th scope="col">Source</th>
            </tr>
          </thead>
          <tbody>
            @for (node of supplierData | slice: 1; track $index) {              
              <tr>
                <td>
                  <a [href]="node.link" target="_blank" matTooltip="View Zint Profile">{{node.label}}</a>
                </td>
                <td>
                  <a [href]="node.source_url" target="_blank">View<i class="fal fa-external-link fa-xs ml-1"></i>
                </a></td>
              </tr>
            
            }
          </tbody>
        </table> 
      }       
      </div>                 

  </mat-expansion-panel>
}

@if (officersPSCsData?.length) {
  <mat-expansion-panel id="corporate_officers_section" [style.order]="getSectionOrder('corporate_officers')" hideToggle
    (expandedChange)="setSectionExpandedState('corporate_officers_section')">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('corporate_officers')"></i>
          Corporate Officers and Legal PSCs
          <sup class="section-counter-badge">
            {{navSectionPolledCount['corporate_officers']}}
          </sup>
          <i *ngIf="expandedSections['corporate_officers_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div id="officersCarousel" class="carousel slide custom-carousel events-carousel" data-ride="carousel" data-interval="false">

      <div *ngIf="officersPSCsData.length > 3" class="controls-wrapper">
        <a class="custom-control-left" href="#officersCarousel" data-slide="prev">
          <i role="button" class="fas fa-caret-circle-left"></i>
        </a>
        <ol class="carousel-indicators">
          <li *ngFor="let chunk of chunkForCarousel(officersPSCsData); let i = index" [class.active]="i === 0"
            data-target="#officersCarousel" [attr.data-slide-to]="i">
          </li>
        
        </ol>
        <a class="custom-control-right" href="#officersCarousel" data-slide="next">
          <i role="button" class="fas fa-caret-circle-right"></i>
        </a>
      </div>

      <div class="carousel-inner">  
        @for (chunk of chunkForCarousel(officersPSCsData); track $index) {
        <div class="carousel-item" [ngClass]="{'active': officersPSCsData.indexOf(chunk[0]) === 0}">         
          <div class="event-list-container">
            @for (officer of chunk; track $index) {
              <div class="event-carousel-card text-center">
                <h5 class="text-grey-600"><i class="fal fa-building display-4"></i></h5>
                <h5>{{officer.name}}</h5>
                <span *ngIf="officer.role">{{officer.role}}</span>
                <span *ngIf="officer.tenure">{{officer.tenure}}</span>
                <span *ngIf="officer.legal_authority">{{officer.legal_authority}}</span>
                <span *ngIf="officer.legal_form">{{officer.legal_form}}</span>
                <span>
                @for (control of officer.natures_of_control; track $index) {
                  {{control}}<br>
                }
                </span>
              </div>
            }
          </div>
        </div>
      }
      </div>
      
    </div>
  </mat-expansion-panel>
}

@if (company.similar_organisations?.length) {
  <mat-expansion-panel id="similar_organisations_section" [style.order]="getSectionOrder('similar_organisations')" hideToggle
    (expandedChange)="setSectionExpandedState('similar_organisations_section')" class="mb-5">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>
          <i class="mr-1" [ngClass]="getSectionIcon('similar_organisations')"></i>
          Similar Organisations
          <sup class="section-counter-badge">
            {{navSectionPolledCount['similar_organisations']}}
          </sup>
          <i *ngIf="expandedSections['similar_organisations_section']" class="ml-1 far fa-angle-up"></i>
        </h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div id="similarOrgsCarousel" class="carousel slide custom-carousel events-carousel" data-ride="carousel" data-interval="false">
      <div *ngIf="company.similar_organisations.length > (isSmallScreen ? 3 : 4)" class="controls-wrapper">
        <a class="custom-control-left" href="#similarOrgsCarousel" data-slide="prev">
          <i role="button" class="fas fa-caret-circle-left"></i>
        </a>
        <ol class="carousel-indicators">
          <li *ngFor="let chunk of chunkForCarousel(company.similar_organisations, isSmallScreen ? 3 : 4); let i = index" [class.active]="i === 0"
            data-target="#similarOrgsCarousel" [attr.data-slide-to]="i">
          </li>
      
        </ol>
        <a class="custom-control-right" href="#similarOrgsCarousel" data-slide="next">
          <i role="button" class="fas fa-caret-circle-right"></i>
        </a>
      </div>
      <div class="carousel-inner">
        @for (chunk of chunkForCarousel(company.similar_organisations, isSmallScreen ? 3 : 4); track $index) {
        <div class="carousel-item" [ngClass]="{'active': company.similar_organisations.indexOf(chunk[0]) === 0}">
          <div class="similar-orgs-carousel-container">
            @for (similarOrg of chunk; track $index) {
            <div class="similar-org-card">
              <img [src]="similarOrg.annotated_screenshot_url" alt="company"
                onError="this.src='https://placehold.co/400x220/f5f4f8/lightgrey?text=no+webpage+preview'">
              <div class="similar-org-details">
                <h5 class="text-center mb-0">{{similarOrg.company_name}}</h5>
                <span *ngIf="similarOrg.company_revenue" class="text-center">Revenue: <span class="revenue-figure">{{similarOrg.company_revenue |
                    companyDataPipe: 'Revenue': null}}</span></span>
        
                <span *ngIf="similarOrg.primary_sic_description" class="badge-light-grey align-self-center text-wrap">
                  <truncate-text [inputText]="
                  similarOrg.primary_sic_description" [limit]="8"></truncate-text>
                </span>
        
                <span *ngIf="similarOrg.url"><a href="{{ similarOrg.url }}" target="_blank">{{
                    similarOrg.domain }}</a></span>
        
                <span *ngIf="similarOrg.number_of_employees_banding">Estimated Headcount: {{
                  similarOrg.number_of_employees_banding }}</span>
        
                <span *ngIf="similarOrg.summary" class="">{{ similarOrg.summary
                  }}</span>        
        
            </div>
            <a role="button" [href]="similarOrg.zint_link" target="_blank" class="rounded-btn-base rounded-btn-light btn-p-md btn-link m-3">View Profile</a>
            </div>
            }
          </div>
        </div>
        }
      </div>
    </div>
  </mat-expansion-panel>
}

  </mat-accordion>

  </section>


</main>
}



<!--******************* Modals and modal components ******************/-->
<!--******************* Modals and modal components ******************/-->
<!--******************* Modals and modal components ******************/-->
<!--******************* Modals and modal components ******************/-->

<div class="modal fade" id="reOrderSectionsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Reorder Sections</h4>
      </div>
      <div class="modal-body">
        <p>Reorder the sections by dragging items up or down.<br>
        <small>Note: Company Overview is always displayed first and thus excluded from the reorder list below.</small>
        </p>        
        <div cdkDropList
          class="reorder-list"
          (cdkDropListDropped)="dragSort($event)">
          @for (item of tempReOrderingList; track item; let index = $index) {
          <div class="reorder-item-box"
            cdkDrag>
            <span>{{index + 1}}</span>
            <span>
              <i class="mr-1" [ngClass]="getSectionIcon(item)"></i>
              {{item | underscoreToUpper}}
            </span>
          </div>
          }
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="rounded-btn-base rounded-btn-vivid btn-p-md"
          (click)="saveReOrderedSections()">Save changes</button>
      </div>
    </div>
  </div>
</div>

@if (company.strategic_insights?.length) {
    <ai-insights-modal [aiAccountsSummaries]="company.strategic_insights" [companyId]="companyNumber"></ai-insights-modal>
}

<div class="modal" id="allKeyPeopleModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xxl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Key People</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row justify-content-around overflow-auto mx-0 mt-4">
        @for (person of company.key_people; track $index) {
          <div class="col-10 col-sm-6 col-lg-4 col-xl-3 mb-30">
            <key-people [person]="person" [personIndex]="$index"></key-people>
          </div>
        }
      </div>
      <div class="modal-footer">
        <button type="button" class="rounded-btn-base rounded-btn-vivid btn-p-lg" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="impExpModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="importsModalLabel">{{impExpModalType | titlecase}} Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
        <import-export-details
          [inputParams]="{'detailedImportsOrExports': impExpModalType === 'imports'? detailedImportsData : detailedExportsData}" [newUI]="true">
        </import-export-details>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="basic-button-base button-dark" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmDownloadModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="importsModalLabel">Confirm Download</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        This will use {{allGovernmentContracts?.length}} download credits.
        <button class="d-block mx-auto my-3 rounded-btn-base rounded-btn-vivid-outline btn-p-lg" (click)="downloadAllGovtContracts()">
          <i class="fal fa-download mr-1"></i>Confirm</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="followZintGrowModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="importsModalLabel">Pick the Zint Grow column</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex flex-column w-50 mx-auto">
        @for (column of zintGrowColumns; track $index) {          
          <button [disabled]="!column.can_edit" class="rounded-btn-base btn-p-md m-2" [ngClass]="column.can_edit ? 'rounded-btn-white':'rounded-btn-light text-grey-600'" (click)="followCompanyInZintGrow(column)">{{column.name}}</button>                   
        }
        </div>
      </div>
    </div>
  </div>
</div>



  


<opensearch-modal 
[openSearchModalTitle]="openSearchModalTitle" 
[currentOpenSearchType]="currentOpenSearchType" 
[accountsResults]="accountsResults" 
[fullWebsiteResults]="fullWebsiteResults" 
[yearAccountsMadeUpToDates]="yearAccountsMadeUpToDates" 
[fullWebsiteSearchTerm]="fullWebsiteSearchTerm" 
[accountsSearchText]="accountsSearchText" 
[accountsDateSelected]="accountsDateSelected"
[loadingStatus]="loadingStatus"
[companyNumber]="companyNumber"
(emitWebsiteSearch)="fullWebsiteSearch($event)" (emitAccountsSearch)="accountsSearch($event)"></opensearch-modal>

<zint-grow-modal></zint-grow-modal>
<tagging></tagging>
<salesforce-export-modal></salesforce-export-modal>
<hubspot-export></hubspot-export>
<custom-insights></custom-insights>
