import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CommunicationService {
  private userAccountId = new BehaviorSubject<number>(0);
  private accountEmail = new BehaviorSubject<any>('');
  private isSuperUser = new BehaviorSubject<any>(false);
  private isParentOrgSuperUser = new BehaviorSubject<any>(false);
  private isReadOnlyUser = new BehaviorSubject<any>(false);
  private hasPropensityScoreAccess = new BehaviorSubject<any>(false);
  private hasSalesforceAccessMessage = new BehaviorSubject<any>(false);
  private hasSalesforceV2AccessMessage = new BehaviorSubject<any>(false);

  private hasDeepCrawlAccessMessage = new BehaviorSubject<any>(false);
  private hasRecentAccountsAccessMessage = new BehaviorSubject<any>(false);
  private hasMultiplePostcodeSearchAccessMessage = new BehaviorSubject<any>(
    false
  );
  private hasEnergyAndEmissionsAccessMessage = new BehaviorSubject<any>(false);
  private hasPatentsAccessMessage = new BehaviorSubject<any>(false);
  private hasShareFilingsAccessMessage = new BehaviorSubject<any>(false);
  private hasGovernmentContractsAccessMessage = new BehaviorSubject<any>(false);
  private hasCompanyChargesAccessMessage = new BehaviorSubject<any>(false);
  private hasCompanyMatcherAccessMessage = new BehaviorSubject<any>(false);

  private hasCrossSellAccessMessage = new BehaviorSubject<any>(false);
  private hasMarketMappingReportAccessMessage = new BehaviorSubject<any>(false);
  private hasAIPitchBuilderAccessMessage = new BehaviorSubject<any>(false);
  private hasSupplierDataAccessMessage = new BehaviorSubject<any>(false);
  private hasTerritoryAssignmentAccessMessage = new BehaviorSubject<any>(false);
  private hasZintGrowAccessMessage = new BehaviorSubject<any>(false);
  private hasAIAccountsSummaryAccess = new BehaviorSubject<any>(false);
  private hasSemanticSearchAccessMessage = new BehaviorSubject<any>(false);
  private hasCalendarIntegrationAccess = new BehaviorSubject<any>(false);
  private hasHubspotAccess = new BehaviorSubject<any>(false);
  private hasAISearchAccess = new BehaviorSubject<any>(false);
  private hasAIResearchAssistantAccess = new BehaviorSubject<any>(false);
  private hasAICompanyAssistantAccess = new BehaviorSubject<any>(false);
  private hasCustomInsightsAccess = new BehaviorSubject<any>(false);
  private pageLimitFromPlan = new BehaviorSubject<any>(10);

  sendUserAccountId(userAccountId: number) {
    this.userAccountId.next(userAccountId);
  }
  sendEmailMessage(email: string) {
    this.accountEmail.next(email);
  }
  sendIsSuperUserMessage(isSuperUser: boolean) {
    this.isSuperUser.next(isSuperUser);
  }
  sendIsParentOrgSuperUserMessage(isParentOrgSuperUser: boolean) {
    this.isParentOrgSuperUser.next(isParentOrgSuperUser);
  }
  sendIsReadOnlyUserMessage(isReadOnlyUser: boolean) {
    this.isReadOnlyUser.next(isReadOnlyUser);
  }
  sendHasPropensityScoreAccessMessage(hasPropensityScoreAccess: boolean) {
    this.hasPropensityScoreAccess.next(hasPropensityScoreAccess);
  }
  sendHasSalesforceAccessMessage(hasSalesforceAccessMessage: boolean) {
    this.hasSalesforceAccessMessage.next(hasSalesforceAccessMessage);
  }
  sendHasSalesforceV2AccessMessage(hasSalesforceV2AccessMessage: boolean) {
    this.hasSalesforceV2AccessMessage.next(hasSalesforceV2AccessMessage);
  }
  sendHasDeepCrawlAccessMessage(hasDeepCrawlAccessMessage: boolean) {
    this.hasDeepCrawlAccessMessage.next(hasDeepCrawlAccessMessage);
  }
  sendHasRecentAccountsAccessMessage(hasRecentAccountsAccessMessage: boolean) {
    this.hasRecentAccountsAccessMessage.next(hasRecentAccountsAccessMessage);
  }
  sendHasMultiplePostcodeSearchAccessMessage(
    hasMultiplePostcodeSearchAccessMessage: boolean
  ) {
    this.hasMultiplePostcodeSearchAccessMessage.next(
      hasMultiplePostcodeSearchAccessMessage
    );
  }
  sendHasEnergyAndEmissionsAccessMessage(
    hasEnergyAndEmissionsAccessMessage: boolean
  ) {
    this.hasEnergyAndEmissionsAccessMessage.next(
      hasEnergyAndEmissionsAccessMessage
    );
  }
  sendHasPatentsAccessMessage(hasPatentsAccessMessage: boolean) {
    this.hasPatentsAccessMessage.next(hasPatentsAccessMessage);
  }
  sendHasShareFilingsAccessMessage(hasShareFilingsAccessMessage: boolean) {
    this.hasShareFilingsAccessMessage.next(hasShareFilingsAccessMessage);
  }
  sendHasGovernmentContractsAccessMessage(
    hasGovernmentContractsAccessMessage: boolean
  ) {
    this.hasGovernmentContractsAccessMessage.next(
      hasGovernmentContractsAccessMessage
    );
  }
  sendHasCompanyChargesAccessMessage(hasCompanyChargesAccessMessage: boolean) {
    this.hasCompanyChargesAccessMessage.next(hasCompanyChargesAccessMessage);
  }
  sendHasCompanyMatcherAccessMessage(hasCompanyMatcherAccessMessage: boolean) {
    this.hasCompanyMatcherAccessMessage.next(hasCompanyMatcherAccessMessage);
  }
  sendHasZintGrowAccessMessage(hasZintGrowAccessMessage: boolean) {
    this.hasZintGrowAccessMessage.next(hasZintGrowAccessMessage);
  }
  sendHasCrossSellAccessMessage(hasCrossSellAccessMessage: boolean) {
    this.hasCrossSellAccessMessage.next(hasCrossSellAccessMessage);
  }
  sendHasMarketMappingReportAccessMessage(
    hasMarketMappingReportAccessMessage: boolean
  ) {
    this.hasMarketMappingReportAccessMessage.next(
      hasMarketMappingReportAccessMessage
    );
  }

  sendHasAIPitchBuilderAccessMessage(hasAIPitchBuilderAccessMessage: boolean) {
    this.hasAIPitchBuilderAccessMessage.next(hasAIPitchBuilderAccessMessage);
  }

  sendHasSupplierDataAccessMessage(hasSupplierDataAccessMessage: boolean) {
    this.hasSupplierDataAccessMessage.next(hasSupplierDataAccessMessage);
  }

  sendHasTerritoryAssignmentAccessMessage(
    hasTerritoryAssignmentAccessMessage: boolean
  ) {
    this.hasTerritoryAssignmentAccessMessage.next(
      hasTerritoryAssignmentAccessMessage
    );
  }

  sendHasAIAccountsSummaryAccess(hasAIAccountsSummary: boolean) {
    this.hasAIAccountsSummaryAccess.next(hasAIAccountsSummary);
  }

  sendHasSemanticSearchAccessMessage(hasSemanticSearchAccessMessage: boolean) {
    this.hasSemanticSearchAccessMessage.next(hasSemanticSearchAccessMessage);
  }

  sendHasCalendarIntegrationAccessMessage(
    hasCalendarIntegrationAccess: boolean
  ) {
    this.hasCalendarIntegrationAccess.next(hasCalendarIntegrationAccess);
  }

  sendHasHubspotAccess(hasHubspotAccess: boolean) {
    this.hasHubspotAccess.next(hasHubspotAccess);
  }
  sendAISearchAccess(hasAISearchAccess: boolean) {
    this.hasAISearchAccess.next(hasAISearchAccess);
  }
  sendHasAIResearchAssistantAccess(hasAIResearchAssistantAccess: boolean) {
    this.hasAIResearchAssistantAccess.next(hasAIResearchAssistantAccess);
  }
  sendHasAICompanyAssistantAccess(hasAICompanyAssistantAccess: boolean) {
    this.hasAICompanyAssistantAccess.next(hasAICompanyAssistantAccess);
  }
  sendHasCustomInsightsAccess(hasCustomInsightsAccess: boolean) {
    this.hasCustomInsightsAccess.next(hasCustomInsightsAccess);
  }
  sendPageLimitFromPlan(pageLimitFromPlan: number) {
    this.pageLimitFromPlan.next(pageLimitFromPlan);
  }

  getUserAccountId(): Observable<number> {
    return this.userAccountId.asObservable();
  }
  getEmailMessage(): Observable<any> {
    return this.accountEmail.asObservable();
  }
  getIsSuperUserMessage(): Observable<any> {
    return this.isSuperUser.asObservable();
  }
  getIsParentOrgSuperUserMessage(): Observable<any> {
    return this.isParentOrgSuperUser.asObservable();
  }
  getIsReadOnlyUserMessage(): Observable<any> {
    return this.isReadOnlyUser.asObservable();
  }
  getHasPropensityScoreAccessMessage(): Observable<any> {
    return this.hasPropensityScoreAccess.asObservable();
  }
  getHasSalesforceAccessMessage(): Observable<any> {
    return this.hasSalesforceAccessMessage.asObservable();
  }
  getHasSalesforceV2AccessMessage(): Observable<any> {
    return this.hasSalesforceV2AccessMessage.asObservable();
  }
  getHasDeepCrawlAccessMessage(): Observable<any> {
    return this.hasDeepCrawlAccessMessage.asObservable();
  }
  getHasRecentAccountsAccessMessage(): Observable<any> {
    return this.hasRecentAccountsAccessMessage.asObservable();
  }
  getHasMultiplePostcodeSearchAccessMessage(): Observable<any> {
    return this.hasMultiplePostcodeSearchAccessMessage.asObservable();
  }
  getHasEnergyAndEmissionsAccessMessage(): Observable<any> {
    return this.hasEnergyAndEmissionsAccessMessage.asObservable();
  }
  getHasPatentsAccessMessage(): Observable<any> {
    return this.hasPatentsAccessMessage.asObservable();
  }
  getHasShareFilingsAccessMessage(): Observable<any> {
    return this.hasShareFilingsAccessMessage.asObservable();
  }
  getHasGovernmentContractsAccessMessage(): Observable<any> {
    return this.hasGovernmentContractsAccessMessage.asObservable();
  }
  getHasCompanyChargesAccessAccessMessage(): Observable<any> {
    return this.hasCompanyChargesAccessMessage.asObservable();
  }
  getHasCompanyMatcherAccessMessage(): Observable<any> {
    return this.hasCompanyMatcherAccessMessage.asObservable();
  }
  getHasZintGrowAccessMessage(): Observable<any> {
    return this.hasZintGrowAccessMessage.asObservable();
  }
  getHasCrossSellAccessMessage(): Observable<any> {
    return this.hasCrossSellAccessMessage.asObservable();
  }
  getHasMarketMappingReportAccessMessage(): Observable<any> {
    return this.hasMarketMappingReportAccessMessage.asObservable();
  }
  getHasAIPitchBuilderAccessMessage(): Observable<any> {
    return this.hasAIPitchBuilderAccessMessage.asObservable();
  }
  getHasSupplierDataAccessMessage(): Observable<any> {
    return this.hasSupplierDataAccessMessage.asObservable();
  }
  getHasTerritoryAssignmentAccessMessage(): Observable<any> {
    return this.hasTerritoryAssignmentAccessMessage.asObservable();
  }
  getHasAIAccountsSummaryAccess(): Observable<any> {
    return this.hasAIAccountsSummaryAccess.asObservable();
  }
  getHasSemanticSearchAccessMessage(): Observable<any> {
    return this.hasSemanticSearchAccessMessage.asObservable();
  }
  getHasCalendarIntegrationAccess(): Observable<any> {
    return this.hasCalendarIntegrationAccess.asObservable();
  }
  getHubspotAccess(): Observable<any> {
    return this.hasHubspotAccess.asObservable();
  }
  getAISearchAccess(): Observable<any> {
    return this.hasAISearchAccess.asObservable();
  }
  getHasAIResearchAssistantAccessMessage(): Observable<any> {
    return this.hasAIResearchAssistantAccess.asObservable();
  }
  getHasAICompanyAssistantAccessMessage(): Observable<any> {
    return this.hasAICompanyAssistantAccess.asObservable();
  }
  getHasCustomInsightsAccessMessage(): Observable<any> {
    return this.hasCustomInsightsAccess.asObservable();
  }
  getPageLimitFromPlanMessage(): Observable<any> {
    return this.pageLimitFromPlan.asObservable();
  }
}
