import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase',
})
export class KebabCasePipe implements PipeTransform {
  transform(value: string, postfix: string = '', prefix: string = ''): string {
    if (!value) {
      return value;
    }

    // replace spaces with hyphens
    let transformedValue = value.trim().toLowerCase().replace(/\s+/g, '-');

    // add prefix and postfix if provided
    if (prefix) {
      transformedValue = `${prefix}-${transformedValue}`;
    }

    if (postfix) {
      transformedValue = `${transformedValue}-${postfix}`;
    }

    return transformedValue;
  }
}
