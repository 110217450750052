import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { publicMethods } from '../../globals';
declare var $: any;

@Component({
  selector: 'ai-accounts-summaries',
  templateUrl: './ai-accounts-summaries.component.html',
  styleUrls: ['./ai-accounts-summaries.component.css'],
})
export class AiAccountsSummariesComponent implements OnInit {
  @Input() aiAccountSummary: any;
  @Input() companyId: string = '';

  fallbackInfoText: string;
  accountsType: string = '';
  totalNumberOfPages: number = null;
  pdfURL: string = '';
  fileName: string = '';

  constructor(
    private cdr: ChangeDetectorRef,
    private pubMethods: publicMethods
  ) {}

  ngOnInit(): void {
    this.accountsType = this.aiAccountSummary.accounts_type;
    this.totalNumberOfPages = this.aiAccountSummary.number_of_pages;
    this.pdfURL = this.aiAccountSummary.pdf_url;
    this.fileName = this.aiAccountSummary.file_name;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

}
