import { IConfigSlider } from './custom-range-slider';

// NOTE: The label is the main part of a variable name that would be sent to the backend. It should start from the capital later.
//  Final variables will be named "lower*" and "upper*"

export const heatScore: IConfigSlider[] = [
  {
    min: 0,
    max: 100,
    label: 'HeatScore',
    postfix: '%',
  },
];

export const wages: IConfigSlider[] = [
  {
    formName: 'Wages',
    min: 0,
    max: 100000000,
    label: 'Wages',
    prefix: '£',
  },
];


export const numberOfActiveTrademarks: IConfigSlider[] = [
  {
    formName: 'Number Of Active Trademarks',
    min: 0,
    max: 100,
    label: 'NumberOfActiveTrademarks',
  },
];

export const fleet: IConfigSlider[] = [
  {
    formName: 'Vehicle Fleet Size',
    min: 0,
    max: 10000,
    label: 'VehicleFleetSize',
  },  {
    formName: 'Vehicle Fleet Size Authorised',
    min: 0,
    max: 10000,
    label: 'VehicleFleetSizeAuthorised',
  },  {
    formName: 'Trailer Fleet Size',
    min: 0,
    max: 10000,
    label: 'TrailerFleetSize',
  },  {
    formName: 'Trailer Fleet Size Authorised',
    min: 0,
    max: 10000,
    label: 'TrailerFleetSizeAuthorised',
  },
];
