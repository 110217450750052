<div class="row">
    @if(showViewPermissions){
    <div class="col-md-6">
        <h5 class="fw-100">View Access:</h5>
        <add-remove-individual
                 [inputParams]="{
                    'confirmDeleteModalId': 'confirmDeleteViewableUserModal__' + postParamName + '__' + objectWithPermissions.id,
                    'objectAddingOrRemovingIndividual': objectWithPermissions,
                    'attributeNameForIndividuals': 'view_users',
                    'organisationUsers': organisationUsers
                }"
                (ParentFunctionOnAddNewIndividual)="addViewableUser($event[0])"
                (ParentFunctionOnClickToDeleteIndividual)="userToDelete = $event[0];"
        ></add-remove-individual>
        <add-remove-team
                 [inputParams]="{
                    'confirmDeleteModalId': 'confirmDeleteViewableTeamModal__' + postParamName + '__' + objectWithPermissions.id,
                    'objectAddingOrRemovingTeam': objectWithPermissions,
                    'attributeNameForTeams': 'view_teams',
                    'organisationTeams': organisationTeams
                }"
                (ParentFunctionOnAddNewTeam)="addViewableTeam($event[0])"
                (ParentFunctionOnClickToDeleteTeam)="teamToDelete = $event[0]"
        ></add-remove-team>
    </div>
    }

    @if(showEditPermissions){
    <div class="col-md-6">
        <h5 class="fw-100">Edit Access:</h5>
        <add-remove-individual
                 [inputParams]="{
                    'confirmDeleteModalId': 'confirmDeleteEditableUserModal__' + postParamName + '__' + objectWithPermissions.id,
                    'objectAddingOrRemovingIndividual': objectWithPermissions,
                    'attributeNameForIndividuals': 'edit_users',
                    'organisationUsers': organisationUsers
                }"
                (ParentFunctionOnAddNewIndividual)="addEditableUser($event[0])"
                (ParentFunctionOnClickToDeleteIndividual)="userToDelete = $event[0];"
        ></add-remove-individual>
        <add-remove-team
                 [inputParams]="{
                    'confirmDeleteModalId': 'confirmDeleteEditableTeamModal__' + postParamName + '__' + objectWithPermissions.id,
                    'objectAddingOrRemovingTeam': objectWithPermissions,
                    'attributeNameForTeams': 'edit_teams',
                    'organisationTeams': organisationTeams
                }"
                (ParentFunctionOnAddNewTeam)="addEditableTeam($event[0])"
                (ParentFunctionOnClickToDeleteTeam)="teamToDelete = $event[0]"
        ></add-remove-team>
    </div>
    }
</div>


<div class="modal fade" [id]="'confirmDeleteViewableUserModal__' + postParamName + '__' + objectWithPermissions.id" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="userToDelete">
                <p>This will remove view access for {{ userToDelete.email }}</p>
                <button class="basic-button-base button-dark" (click)="removeViewableUser()">Confirm</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" [id]="'confirmDeleteViewableTeamModal__' + postParamName + '__' + objectWithPermissions.id" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="teamToDelete">
                <p>This will remove view access for {{ teamToDelete.name }}</p>
                <button class="basic-button-base button-dark" (click)="removeViewableTeam()">Confirm</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" [id]="'confirmDeleteEditableUserModal__' + postParamName + '__' + objectWithPermissions.id" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="userToDelete">
                <p>This will remove edit access for {{ userToDelete.email }}</p>
                <button class="basic-button-base button-dark" (click)="removeEditableUser()">Confirm</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" [id]="'confirmDeleteEditableTeamModal__' + postParamName + '__' + objectWithPermissions.id" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="teamToDelete">
                <p>This will remove edit access for {{ teamToDelete.name }}</p>
                <button class="basic-button-base button-dark" (click)="removeEditableTeam()">Confirm</button>
            </div>
        </div>
    </div>
</div>
