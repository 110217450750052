import { Component, Input } from '@angular/core';
import {
  forceCollide,
  forceLink,
  forceManyBody,
  forceSimulation,
} from 'd3-force';
import { CompanyService } from '../../services/company.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cross-sell-graph',
  templateUrl: './cross-sell-graph.component.html',
  styleUrl: './cross-sell-graph.component.css',
})
export class CrossSellGraphComponent {
  @Input() companyNumber: any;

  isFullPage: boolean = false;
  isLoadingRelatedPeopleGraph: boolean = false;
  relatedPeopleGraph: any;
  shouldAutoCentreRelatedPeopleGraph = true;
  force = forceSimulation<any>()
    .force('charge', forceManyBody().strength(-10000)) // The strength of the repulsion. Default is -150
    .force('collide', forceCollide(25)) // The radius distance of each repel "bubble" put around each node.
    .alphaDecay(0.1); // How fast it calculates. 1 is fast but may not be an optimum layout. Default is 0.028 I think.
  forceLink = forceLink<any, any>()
    .id(node => node.id)
    .distance(() => 400); // The length of the links. Default is 100

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCrossSellGraph();
  }

  getIdFromPath(): void {
    if (this.route.snapshot.routeConfig.path.includes('cross-sell')) {
      this.isFullPage = true;
      this.companyNumber = this.route.snapshot.paramMap.get('id');
    }
  }

  getCrossSellGraph(): void {
    this.getIdFromPath();

    this.isLoadingRelatedPeopleGraph = true;
    this.companyService
      .getRelatedPeopleGraph(<string>this.companyNumber)
      .subscribe(graphInfo => this.updateRelatedPeopleGraphs(graphInfo));
  }

  updateRelatedPeopleGraphs(graphInfo) {
    this.relatedPeopleGraph = graphInfo['related_people_graph'];
    this.isLoadingRelatedPeopleGraph = false;
    setTimeout(() => {
      this.shouldAutoCentreRelatedPeopleGraph = false;
    }, 800);
  }
}
