@if (isLoadingRelatedPeopleGraph) {
    <div class="d-flex justify-content-center align-items-center mt-1"><zint-loader size="xSmall"></zint-loader> <span class="ml-2">Loading Graph</span></div>
}
@else {
    @if (relatedPeopleGraph?.nodes?.length === 0){
        <p class="p-2">No cross sell opportunities identified.</p>
    }
    
    @if(relatedPeopleGraph?.nodes?.length > 0) {
        <div [ngClass]="isFullPage ? 'full-page-graph-holder': 'ngx-graph-holder'">
            <ngx-graph #graph2 layout="d3ForceDirected"
                [links]="relatedPeopleGraph.links" [nodes]="relatedPeopleGraph.nodes" [panOnZoom]="true"
                [enableZoom]="true" [zoomSpeed]="0.05" [zoomLevel]="0.25" [minZoomLevel]="0.1"
                [maxZoomLevel]="2.0" [autoCenter]="shouldAutoCentreRelatedPeopleGraph" [legend]="false"
                [layoutSettings]="{
                    force: this.force,
                    forceLink: this.forceLink
                }">

                <ng-template #defsTemplate>
                    <svg:marker id="arrowRelatedPeople" viewBox="0 -5 10 10" refX="8" refY="0"
                        markerWidth="4" markerHeight="4" class="svg-arrow-fill">
                        <svg:path d="M0,-5L10,0L0,5" />
                    </svg:marker>
                </ng-template>


                <ng-template #nodeTemplate let-node>
                    <svg:g class="node">
                        <svg:rect style="stroke-width:1px; stroke: #dee2e6" rx="10" ry="10"
                            [attr.width]="node.dimension.width" [attr.height]="node.dimension.height"
                            [attr.fill]="node.node_type=='business' ? '#f4f5f7' : '#000a36'" />
                        <svg:text alignment-baseline="central" dx="10" dy="20"
                            [attr.fill]="node.node_type=='business' ? '#000' : '#f4f5f7'">
                            <tspan x="0.6em" dy="1.2em">{{node.label}}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                            <tspan x="1.2em" dy="1.2em" *ngIf="node.revenue">Revenue: {{ node.revenue | companyDataPipe:'Revenue':'' }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                            <tspan x="1.2em" dy="1.2em" *ngIf="node.sic_code_1">{{ node.sic_code_1 }}&nbsp;&nbsp;&nbsp;&nbsp;</tspan>
                            <a attr.xlink:href="{{node.link}}" target="_blank" *ngIf="node.link">
                                <tspan x="1.2em" dy="1.2em">View Organisation</tspan>
                            </a>
                        </svg:text>
                    </svg:g>
                </ng-template>

                <ng-template #linkTemplate let-link>
                    <svg:g class="edge" ngx-tooltip [tooltipPlacement]="'top'" [tooltipType]="'tooltip'" [tooltipTitle]="link.label">
                        <svg:path class="line svg-arrow-stroke" [attr.stroke-width]="link.line_width" stroke-opacity="0.5" marker-end="url(#arrowRelatedPeople)">
                        </svg:path>
                    </svg:g>
                </ng-template>
            </ngx-graph>
        </div>

    }

}





