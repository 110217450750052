import { IConfigSlider } from '../classes/custom-range-slider';

export const tangibleAssets: IConfigSlider[] = [
  {
    formName: 'Land And Buildings',
    min: 0,
    max: 2000000000,
    label: 'LandAndBuildingsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Land And Buildings Cost',
    min: 0,
    max: 3000000000,
    label: 'LandAndBuildingsCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Land And Buildings Additions',
    min: 0,
    max: 2000000000,
    label: 'LandAndBuildingsAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Land And Buildings Disposals',
    min: 0,
    max: 300000000,
    label: 'LandAndBuildingsDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Land And Buildings Depreciation',
    min: 0,
    max: 2000000000,
    label: 'LandAndBuildingsDepreciationTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Plant And Machinery',
    min: 0,
    max: 3000000000,
    label: 'PlantAndMachineryTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Plant And Machinery Cost',
    min: 0,
    max: 3000000000,
    label: 'PlantAndMachineryCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Plant And Machinery Additions',
    min: 0,
    max: 3000000000,
    label: 'PlantAndMachineryAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Plant And Machinery Disposals',
    min: 0,
    max: 300000000,
    label: 'PlantAndMachineryDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Plant And Machinery Depreciation',
    min: 0,
    max: 2000000000,
    label: 'PlantAndMachineryDepreciationTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Furniture And Fittings',
    min: 0,
    max: 2000000000,
    label: 'FurnitureAndFittingsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Furniture And Fittings Cost',
    min: 0,
    max: 2000000000,
    label: 'FurnitureAndFittingsCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Furniture And Fittings Additions',
    min: 0,
    max: 2000000000,
    label: 'FurnitureAndFittingsAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Furniture And Fittings Disposals',
    min: 0,
    max: 100000000,
    label: 'FurnitureAndFittingsDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Furniture And Fittings Depreciation',
    min: 0,
    max: 900000000,
    label: 'FurnitureAndFittingsDepreciationTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Vehicles',
    min: 0,
    max: 2000000000,
    label: 'VehiclesTangibleAssets',
    prefix: '£',
  },  
  {
    formName: 'Vehicles Cost',
    min: 0,
    max: 2000000000,
    label: 'VehiclesCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Vehicles Additions',
    min: 0,
    max: 3000000000,
    label: 'VehiclesAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Vehicles Disposals',
    min: 0,
    max: 2000000000,
    label: 'VehiclesDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Vehicles Depreciation',
    min: 0,
    max: 2000000000,
    label: 'VehiclesDepreciationTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Equipment',
    min: 0,
    max: 2000000000,
    label: 'ComputerEquipmentTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Equipment Cost',
    min: 0,
    max: 2000000000,
    label: 'ComputerEquipmentCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Equipment Additions',
    min: 0,
    max: 700000000,
    label: 'ComputerEquipmentAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Equipment Disposals',
    min: 0,
    max: 200000000,
    label: 'ComputerEquipmentDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Equipment Depreciation',
    min: 0,
    max: 2000000000,
    label: 'ComputerEquipmentDepreciationTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Leasehold Assets',
    min: 0,
    max: 2000000000,
    label: 'LeaseholdAssetsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Leasehold Assets Cost',
    min: 0,
    max: 300000000,
    label: 'LeaseholdAssetsCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Leasehold Assets Additions',
    min: 0,
    max: 200000000,
    label: 'LeaseholdAssetsAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Leasehold Assets Disposals',
    min: 0,
    max: 30000000,
    label: 'LeaseholdAssetsDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Leasehold Assets Depreciation',
    min: 0,
    max: 200000000,
    label: 'LeaseholdAssetsDepreciationTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Assets Under Construction',
    min: 0,
    max: 700000000,
    label: 'AssetsUnderConstructionTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Assets Under Construction Cost',
    min: 0,
    max: 700000000,
    label: 'AssetsUnderConstructionCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Assets Under Construction Additions',
    min: 0,
    max: 500000000,
    label: 'AssetsUnderConstructionAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Assets Under Construction Disposals',
    min: 0,
    max: 2000000,
    label: 'AssetsUnderConstructionDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Assets Under Construction Depreciation',
    min: 0,
    max: 20000000,
    label: 'AssetsUnderConstructionDepreciationTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Other',
    min: 0,
    max: 300000000,
    label: 'OtherTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Other Cost',
    min: 0,
    max: 300000000,
    label: 'OtherCostTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Other Additions',
    min: 0,
    max: 200000000,
    label: 'OtherAdditionsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Other Disposals',
    min: 0,
    max: 90000000,
    label: 'OtherDisposalsTangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Other Depreciation',
    min: 0,
    max: 50000000,
    label: 'OtherDepreciationTangibleAssets',
    prefix: '£',
  },
];

export const intangibleAssets: IConfigSlider[] = [
  {
    formName: 'Goodwill',
    min: 0,
    max: 3000000000,
    label: 'GoodwillIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Goodwill Cost',
    min: 0,
    max: 3000000000,
    label: 'GoodwillCostIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Goodwill Additions',
    min: 0,
    max: 800000000,
    label: 'GoodwillAdditionsIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Goodwill Amortisation',
    min: 0,
    max: 1000000000,
    label: 'GoodwillAmortisationIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Software',
    min: 0,
    max: 3000000000,
    label: 'ComputerSoftwareIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Software Cost',
    min: 0,
    max: 3000000000,
    label: 'ComputerSoftwareCostIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Software Additions',
    min: 0,
    max: 2000000000,
    label: 'ComputerSoftwareAdditionsIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Computer Software Amortisation',
    min: 0,
    max: 3000000000,
    label: 'ComputerSoftwareAmortisationIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Patents, Trademarks, and Licences',
    min: 0,
    max: 2000000000,
    label: 'PatentsTrademarksLicencesIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Patents, Trademarks, and Licences Cost',
    min: 0,
    max: 3000000000,
    label: 'PatentsTrademarksLicencesCostIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Patents, Trademarks, and Licences Additions',
    min: 0,
    max: 2000000000,
    label: 'PatentsTrademarksLicencesAdditionsIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Patents, Trademarks, and Licences Amortisation',
    min: 0,
    max: 900000000,
    label: 'PatentsTrademarksLicencesAmortisationIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Other Intangible Assets',
    min: 0,
    max: 400000000,
    label: 'OtherIntangibleAssets',
    prefix: '£',
  },
  {
    formName: 'Other Intangible Assets Cost',
    min: 0,
    max: 400000000,
    label: 'OtherIntangibleAssetsCost',
    prefix: '£',
  },
  {
    formName: 'Other Intangible Assets Additions',
    min: 0,
    max: 100000000,
    label: 'OtherIntangibleAssetsAdditions',
    prefix: '£',
  },
  {
    formName: 'Other Intangible Assets Amortisation',
    min: 0,
    max: 30000000,
    label: 'OtherIntangibleAssetsAmortisation',
    prefix: '£',
  },
];
