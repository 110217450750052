import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { publicMethods, baseUrl } from 'globals';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class CRMIntegrationsService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService
  ) {}

  isLoading = {
    isLoadingCrmModule: false,
    isLoadingSuperuserCrmSettings: false,
    isLoadingSalesforceExport: false,
    isLoadingRevokeHubspotAccess: false,
    isLoadingActivateHubspot: false,
    isLoadingHubspotZintMappingOptions: false,
    isDeletingHubspotZintMappingOptions: false,
    isLoadingHubspotImportSettings: false,
    isLoadingHubspotFieldsForImportConfig: false,
    isLoadingDeleteHubSpotFieldMapping: false,
    isLoadingCreateNewImportConfiguration: false,
  };

  getCRMIntegrationDetails(): Observable<any> {
    this.isLoading['isLoadingCrmModule'] = true;
    return this.http
      .post(
        baseUrl + '/get_crm_integration_details/',
        {},
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingCrmModule'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getCRMIntegrationDetails',
            [],
            this.isLoading
          )
        )
      );
  }

  updateUserAccessLevels(teamMemberId, hasAccess): Observable<any> {
    this.isLoading['isLoadingSuperuserCrmSettings'] = true;
    const params = new HttpParams()
      .set('teamMemberId', teamMemberId)
      .set('hasAccess', hasAccess);
    return this.http
      .post(
        baseUrl + '/update_user_salesforce_access_levels/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSuperuserCrmSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'updateUserAccessLevels',
            [],
            this.isLoading
          )
        )
      );
  }

  changeSalesforceEnableForAllSettings(
    salesforceAutomaticallyEnabledForAll
  ): Observable<any> {
    this.isLoading['isLoadingSuperuserCrmSettings'] = true;
    const params = new HttpParams().set(
      'salesforceAutomaticallyEnabledForAll',
      salesforceAutomaticallyEnabledForAll
    );
    return this.http
      .post(
        baseUrl + '/change_salesforce_enabled_for_all_setting/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSuperuserCrmSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'changeSalesforceEnableForAllSettings',
            [],
            this.isLoading
          )
        )
      );
  }

  selectSalesforceObject(salesforceObject): Observable<any> {
    this.isLoading['isLoadingSuperuserCrmSettings'] = true;
    const params = new HttpParams().set('salesforceObject', salesforceObject);
    return this.http
      .post(
        baseUrl + '/select_salesforce_object/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSuperuserCrmSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'selectSalesforceObject',
            [],
            this.isLoading
          )
        )
      );
  }

  saveZintToSalesforceMappings(
    zintCustomColumnsToSalesforceAttributesMapping,
    zintAttributesToSalesforceAttributesMapping
  ): Observable<any> {
    this.isLoading['isLoadingSuperuserCrmSettings'] = true;
    const params = new HttpParams()
      .set(
        'zintCustomColumnsToSalesforceAttributesMapping',
        this.pubMethods.stringifyDictForGetParams(
          zintCustomColumnsToSalesforceAttributesMapping
        )
      )
      .set(
        'zintAttributesToSalesforceAttributesMapping',
        this.pubMethods.stringifyDictForGetParams(
          zintAttributesToSalesforceAttributesMapping
        )
      );
    return this.http
      .post(
        baseUrl + '/save_zint_to_salesforce_mappings/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSuperuserCrmSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'saveZintToSalesforceMappings',
            [],
            this.isLoading
          )
        )
      );
  }

  addSalesforceAttributeToConstantMapping(
    salesforceAttributeName,
    constantToSet
  ): Observable<any> {
    this.isLoading['isLoadingSuperuserCrmSettings'] = true;
    const params = new HttpParams()
      .set('salesforceAttributeName', salesforceAttributeName)
      .set('constantToSet', constantToSet);
    return this.http
      .post(
        baseUrl + '/add_salesforce_attribute_to_constant_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSuperuserCrmSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addSalesforceAttributeToConstantMapping',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteSalesforceAttributeToConstantMapping(
    salesforceAttributeName
  ): Observable<any> {
    this.isLoading['isLoadingSuperuserCrmSettings'] = true;
    const params = new HttpParams().set(
      'salesforceAttributeName',
      salesforceAttributeName
    );
    return this.http
      .post(
        baseUrl + '/delete_salesforce_attribute_to_constant_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSuperuserCrmSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'deleteSalesforceAttributeToConstantMapping',
            [],
            this.isLoading
          )
        )
      );
  }

  saveZintToSalesforceUniqueIdentifierMapping(
    zintAttributeUniqueIdentifier,
    salesforceAttributeUniqueIdentifier
  ): Observable<any> {
    this.isLoading['isLoadingSuperuserCrmSettings'] = true;
    const params = new HttpParams()
      .set('zintAttributeUniqueIdentifier', zintAttributeUniqueIdentifier)
      .set(
        'salesforceAttributeUniqueIdentifier',
        salesforceAttributeUniqueIdentifier
      );
    return this.http
      .post(
        baseUrl + '/save_unique_identifer_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSuperuserCrmSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'saveZintToSalesforceUniqueIdentifierMapping',
            [],
            this.isLoading
          )
        )
      );
  }

  exportCompaniesToSalesforcePreCheck(companyIds): Observable<any> {
    this.isLoading['isLoadingSalesforceExport'] = true;
    const params = new HttpParams().set(
      'companyIds',
      this.pubMethods.stringifyListForGetParams(companyIds)
    );
    return this.http
      .post(
        baseUrl + '/pre_check_export_companies_to_salesforce/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSalesforceExport'] = false)),
        catchError(
          this.pubMethods.handleError(
            'exportCompaniesToSalesforcePreCheck',
            [],
            this.isLoading
          )
        )
      );
  }

  exportCompaniesToSalesforce(companyIdsToSalesforceIds): Observable<any> {
    this.isLoading['isLoadingSalesforceExport'] = true;
    const params = new HttpParams().set(
      'companyIdsToSalesforceIds',
      this.pubMethods.stringifyDictForGetParams(companyIdsToSalesforceIds)
    );
    return this.http
      .post(
        baseUrl + '/export_companies_to_salesforce/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingSalesforceExport'] = false)),
        catchError(
          this.pubMethods.handleError(
            'exportCompaniesToSalesforce',
            [],
            this.isLoading
          )
        )
      );
  }

  getHubspotAuthStateAndURL(): Observable<any> {
    this.isLoading['isLoadingHubspotIntegration'] = true;
    const cachedPublicURL: string = sessionStorage.getItem('hs_public_url');
    const params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/hubspot/get_hubspot_state/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        switchMap((response: Record<string, boolean>) => {
          if (response.success === false) {
            //   If NOT success, return the url - either cached or new.
            if (cachedPublicURL) {
              return of({ url: cachedPublicURL });
            } else {
              return this.http
                .post(
                  baseUrl + '/hubspot/oauth/public_app_url/',
                  params,
                  this.auth.helperGetVerifiedPostDict()
                )
                .pipe(
                  tap(response => {
                    sessionStorage.setItem('hs_public_url', response['url']);
                  })
                );
            }
          } else {
            // If success, remove cached url and return response.
            sessionStorage.removeItem('hs_public_url');
            return of(response);
          }
        }),
        tap(_ => (this.isLoading['isLoadingHubspotIntegration'] = false)),
        catchError(
          this.pubMethods.handleError('hubspotIntegration', [], this.isLoading)
        )
      );
  }

  revokeHubspotAccess(): Observable<any> {
    const params = new HttpParams();
    return this.http
      .post(baseUrl + '/hubspot/revoke_hubspot_auth/', params, {
        ...this.auth.helperGetVerifiedPostDict(),
        observe: 'response',
      })
      .pipe(
        tap(_ => (this.isLoading['isLoadingRevokeHubspotAccess'] = false)),
        catchError(
          this.pubMethods.handleError('revokeHubspotAccess', [], this.isLoading)
        )
      );
  }

  activateHubspotIntegration(): Observable<any> {
    const params = new HttpParams();
    this.isLoading['isLoadingActivateHubspot'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/activate_hubspot_integration/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingActivateHubspot'] = false)),
        catchError(
          this.pubMethods.handleError(
            'activateHubspotIntegration',
            [],
            this.isLoading
          )
        )
      );
  }

  toggleHubspotEnrichmentProcess(hubspotObjectName): Observable<any> {
    const params = new HttpParams().set('hubspotObjectName', hubspotObjectName);
    this.isLoading['isLoadingToggleHubspotEnrichment'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/toggle_hubspot_enrichment/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingToggleHubspotEnrichment'] = false)),
        catchError(
          this.pubMethods.handleError(
            'toggleHubspotEnrichmentProcess',
            [],
            this.isLoading
          )
        )
      );
  }

  toggleHubspotAssignOwnerOnCreate(hubspotObjectName): Observable<any> {
    const params = new HttpParams().set('hubspotObjectName', hubspotObjectName);
    this.isLoading['isLoadingToggleHubspotAssignOwnerOnCreate'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/toggle_hubspot_assign_owner_on_create/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ =>
            (this.isLoading['isLoadingToggleHubspotAssignOwnerOnCreate'] =
              false)
        ),
        catchError(
          this.pubMethods.handleError(
            'toggleHubspotAssignOwnerOnCreate',
            [],
            this.isLoading
          )
        )
      );
  }

  getZintAndHubspotMappingOptions(): Observable<any> {
    const params = new HttpParams();
    this.isLoading['isLoadingHubspotZintMappingOptions'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/get_all_hubspot_and_zint_attributes/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['isLoadingHubspotZintMappingOptions'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'getZintAndHubspotMappingOptions',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteHubspotFieldMapping(fieldMappingId): Observable<any> {
    const params = new HttpParams().set('fieldMappingId', fieldMappingId);
    this.isLoading['isDeletingHubspotZintMappingOptions'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/delete_hubspot_property_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['isDeletingHubspotZintMappingOptions'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'deleteHubspotFieldMapping',
            [],
            this.isLoading
          )
        )
      );
  }

  addHubspotPropertyMapping(
    hubspotFieldName,
    zintFieldName,
    shouldOverwrite,
    isLookupField
  ): Observable<any> {
    const params = new HttpParams()
      .set('hubspotFieldName', hubspotFieldName)
      .set('zintFieldName', zintFieldName)
      .set('shouldOverwrite', shouldOverwrite)
      .set('isLookupField', isLookupField);
    this.isLoading['addingHubspotPropertyMapping'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/add_hubspot_property_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['addingHubspotPropertyMapping'] = false)),
        catchError(
          this.pubMethods.handleError(
            'addHubspotPropertyMapping',
            [],
            this.isLoading
          )
        )
      );
  }

  // -------------------- Hubspot Import Mappings. The below is used to configure the import of data from HubSpot into Zint. --------------------

  getHubspotImportSettings(): Observable<any> {
    const params = new HttpParams();
    this.isLoading['isLoadingHubspotImportSettings'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/get_current_hubspot_import_settings/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['isLoadingHubspotImportSettings'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getHubspotImportSettings',
            [],
            this.isLoading
          )
        )
      );
  }

  getHubspotFieldsForImportConfig(): Observable<any> {
    const params = new HttpParams();
    this.isLoading['isLoadingHubspotFieldsForImportConfig'] = true;
    return this.http
      .post(
        baseUrl +
          '/hubspot/get_options_for_hubspot_import_config_configuration/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['isLoadingHubspotFieldsForImportConfig'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'getHubspotImportSettings',
            [],
            this.isLoading
          )
        )
      );
  }

  addHubspotImportFieldMapping(
    hubspotObjectImportConfigId,
    hubspotFieldName,
    columnId
  ): Observable<any> {
    const params = new HttpParams()
      .set('hubspotObjectImportConfigId', hubspotObjectImportConfigId)
      .set('hubspotFieldName', hubspotFieldName)
      .set('columnId', columnId);
    this.isLoading['isLoadingHubspotFieldsForImportConfig'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/add_hubspot_import_field_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['isLoadingHubspotFieldsForImportConfig'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'getHubspotImportSettings',
            [],
            this.isLoading
          )
        )
      );
  }

  deleteHubspotImportFieldMapping(
    hubspotObjectImportConfigId,
    hubspotFieldMappingId
  ): Observable<any> {
    const params = new HttpParams()
      .set('hubspotObjectImportConfigId', hubspotObjectImportConfigId)
      .set('hubspotImportFieldMappingId', hubspotFieldMappingId);
    this.isLoading['isLoadingDeleteHubSpotFieldMapping'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/delete_hubspot_import_field_mapping/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['isLoadingDeleteHubSpotFieldMapping'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'deleteHubspotImportFieldMapping',
            [],
            this.isLoading
          )
        )
      );
  }

  createNewHubspotImportConfiguration(): Observable<any> {
    const params = new HttpParams();
    this.isLoading['isLoadingCreateNewImportConfiguration'] = true;
    return this.http
      .post(
        baseUrl + '/hubspot/activate_hubspot_import_config/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(
          _ => (this.isLoading['isLoadingCreateNewImportConfiguration'] = false)
        ),
        catchError(
          this.pubMethods.handleError(
            'createNewHubspotImportConfiguration',
            [],
            this.isLoading
          )
        )
      );
  }
}
