import { Component, Input } from '@angular/core';

@Component({
  selector: 'ai-insights-modal',
  templateUrl: './ai-insights-modal.component.html',
  styleUrl: './ai-insights-modal.component.css',
})
export class AiInsightsModalComponent {
  @Input() aiAccountsSummaries: Record<string, any>[] = [];
  @Input() companyId = '';
}
