<div class="modal fade" id="tagModal" tabindex="-1" role="dialog" aria-labelledby="tagModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="tagModalLabel">Tag These Organisations</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div *ngIf="tagIdToDelete">
                    <p>Are you sure you want to delete this tag? This will remove the tag from all organisations you have every assigned it to.</p>
                    <button class="basic-button-base button-dark control-buttons input-group-append" (click)="deleteTag(tagIdToDelete)" [attr.disabled]="isLoading && isLoading.loadingUserTags ? 'disabled': null" style="float: right;">
                        <i class="fal fa-spinner fa-spin" *ngIf="isLoading && isLoading.loadingUserTags"></i>
                        Yes, delete
                    </button>
                </div>



                <div *ngIf="!tagIdToDelete && !isEditingCollaborativeTags">
                    <div *ngIf="userTags && userTags.length > 0">
                        Select an existing tag:
                        <div class="input-group xsmall-margin-top">
                            <select [(ngModel)]="selectedTagToSave" (ngModelChange)="clearStatuses()" class="custom-select" *ngIf="isSuperUser">
                                <option *ngFor="let tag of userTags" [ngValue]="tag.id" >{{tag.name}} - {{ tag.owner }} -  created {{ tag.created | date:'d MMM y' }}</option>
                            </select>
                            <select [(ngModel)]="selectedTagToSave" (ngModelChange)="clearStatuses()" class="custom-select" *ngIf="!isSuperUser">
                                <option *ngFor="let tag of userTags" [ngValue]="tag.id" >{{tag.name}} -  created {{ tag.created | date:'d MMM y' }}</option>
                            </select>
                            <button class="basic-button-base button-dark control-buttons input-group-append" (click)="tagCompanies()" [attr.disabled]="isLoading && isLoading.loadingUserTags ? 'disabled': null" style="float: right;">
                                <i class="fal fa-spinner fa-spin" *ngIf="isLoading && isLoading.loadingUserTags"></i>
                                Tag
                            </button>
                            <button class="basic-button-base button-dark control-buttons input-group-append" (click)="untagCompanies()" [attr.disabled]="isLoading && isLoading.loadingUserTags ? 'disabled': null" style="float: right;">
                                <i class="fal fa-spinner fa-spin" *ngIf="isLoading && isLoading.loadingUserTags"></i>
                                Untag
                            </button>
                        </div>
                    </div>

                    
                    <div *ngIf="countOfSuccessfulTagRemovals === 0">
                        <span class="text-warning">No businesses had this tag.</span>
                    </div>
                    <div *ngIf="countOfSuccessfulTagRemovals > 1">
                        <span class="text-success">Successfully removed this tag from {{ countOfSuccessfulTagRemovals }} businesses.</span>
                    </div>
                    <div *ngIf="countOfSuccessfulTagRemovals === 1">
                        <span class="text-success">Successfully removed the tag from this business.</span>
                    </div>


                    <div *ngIf="countOfSuccessfulTags === 0">
                        <span class="text-warning">No new businesses were tagged.</span>
                    </div>
                    <div *ngIf="countOfSuccessfulTags > 1">
                        <span class="text-success">Successfully added this tag to {{ countOfSuccessfulTags }} businesses.</span>
                    </div>
                    <div *ngIf="countOfSuccessfulTags === 1">
                        <span class="text-success">Successfully tagged this business.</span>
                    </div>

                    <div *ngIf="userTags && userTags.length == 0">
                        <span class="text-warning">You have no tags in your account. Why not create your first tag below?</span>
                    </div>

                    <div class="input-group small-margin-top" style="padding-right: 10px; padding-bottom: 10px">
                        <span style="margin-top: 7px; margin-right: 7px;">Or create a new tag:</span>
                        <select [(ngModel)]="teamMemberIdToCreateTagFor" class="custom-select" style="max-width: 25%" *ngIf="isSuperUser && teamMembers">
                            <option *ngFor="let teamMember of teamMembers" [ngValue]="teamMember.userId">{{ teamMember.email }}</option>
                        </select>
                        <input type="text" class="form-control" [(ngModel)]="newTagName" [attr.disabled]="isLoading.loadingTags ? 'disabled': null" placeholder="Tag name" style="border-right: solid 1px #000A36 !important;">
                        <div class="input-group-append">
                            <button class="basic-button-base button-dark control-buttons" type="button" (click)="createNewUserTagAndUpdateTags()" [attr.disabled]="isLoading && isLoading.loadingTags ? 'disabled': null">
                                <i class="fal fa-spinner fa-spin" *ngIf="isLoading && isLoading.loadingTags"></i>
                                Create and Tag
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="isSuperUser && !tagIdToDelete">
                    <button class="basic-button-base button-dark control-buttons collaborative-button" *ngIf="!isEditingCollaborativeTags" (click)="isEditingCollaborativeTags = true">
                        <i class="fal fa-sign-out" style="transform: rotate(90deg);"></i> New Collaborative Tag
                    </button>
                    <button class="basic-button-base button-dark control-buttons collaborative-button" *ngIf="isEditingCollaborativeTags" (click)="isEditingCollaborativeTags = false">
                        <i class="fal fa-sign-out" style="transform: rotate(270deg);"></i> Back
                    </button>
                    <div *ngIf="isEditingCollaborativeTags" class="xsmall-margin-top">
                        <input type="text" class="form-control" [(ngModel)]="newTagName" [attr.disabled]="isLoading.loadingTags ? 'disabled': null" placeholder="Collaborative tag name">
                        <label style="margin-top: 15px;">Viewable by:</label>
                        <div class="form-group overflowing-table select-table">
                            <div *ngFor="let teamMember of teamMembers">
                                <div class="cut-off">
                                    <label class="expandable-label">
                                        <input type="checkbox"
                                           [attr.disabled]="emailOnAccount == teamMember.email ? 'disabled': null"
                                           [attr.name]="'viewableUser' + teamMember.userId"
                                           [ngModelOptions]="{standalone: true}"
                                           value="{{teamMember.userId}}"
                                           [(ngModel)]="teamMember.checkedForViewable"/>
                                        {{ teamMember.email }} &nbsp; <i class="fal fa-users-cog" *ngIf="teamMember.isOrganisationAdmin"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <label>Editable by:</label>
                        <div class="form-group overflowing-table select-table">
                            <div *ngFor="let teamMember of teamMembers">
                                <div class="cut-off">
                                    <label class="expandable-label">
                                        <input type="checkbox"
                                           [attr.name]="'editableUser' + teamMember.userId"
                                           [attr.disabled]="emailOnAccount == teamMember.email ? 'disabled': null"
                                           [ngModelOptions]="{standalone: true}"
                                           value="{{teamMember.userId}}"
                                           (ngModelChange)="!teamMember.checkedForEditable ? teamMember.checkedForViewable = true: {}"
                                           [(ngModel)]="teamMember.checkedForEditable"/>
                                        {{ teamMember.email }} &nbsp; <i class="fal fa-users-cog" *ngIf="teamMember.isOrganisationAdmin"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="input-group-append" style="float:right;">
                            <button class="basic-button-base button-dark control-buttons" type="button" (click)="createNewOrganisationTagAndUpdateTags()" [attr.disabled]="isLoading && isLoading.loadingTags ? 'disabled': null">
                                <i class="fal fa-spinner fa-spin" *ngIf="isLoading && isLoading.loadingTags"></i>
                                Create Collaborative Tag
                            </button>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</div>
