<div class="zint-base-card mb-2">
    <div>
        <p>Individuals:</p>
        <table class="table table-sm table-within-table " [ngClass]="{'zero-margin-bottom': !inputParams.objectAddingOrRemovingIndividual[inputParams.attributeNameForIndividuals] || inputParams.objectAddingOrRemovingIndividual[inputParams.attributeNameForIndividuals].length == 0}">
            <tr *ngFor="let individual of inputParams.objectAddingOrRemovingIndividual[inputParams.attributeNameForIndividuals]">
                <td class="text-grey-300">{{ individual.email }}</td>
                <td>
                    <button class="rounded-btn-base rounded-btn-light-outline btn-p-sm" style="font-size: 10px" (click)="ChildFunctionOnClickToDeleteIndividual(individual, inputParams.objectAddingOrRemovingIndividual)" data-toggle="modal" [attr.data-target]="'#' + inputParams.confirmDeleteModalId"><i class="fal fa-trash"></i></button>
                </td>
            </tr>            
        </table>
        <ng-container *ngIf="inputParams.organisationUsers && inputParams.objectAddingOrRemovingIndividual[inputParams.attributeNameForIndividuals] && inputParams.organisationUsers.length != inputParams.objectAddingOrRemovingIndividual[inputParams.attributeNameForIndividuals].length">
            <select class="custom-select" (change)="ChildFunctionOnAddNewIndividual($event.target.value, inputParams.objectAddingOrRemovingIndividual)" *ngIf="isAddingIndividual">
                <ng-container *ngIf="inputParams.objectAddingOrRemovingIndividual[inputParams.attributeNameForIndividuals]">
                    <option selected></option>
                    <ng-container *ngFor="let organisationUser of inputParams.organisationUsers;">
                        <option [attr.value]="organisationUser.userId" *ngIf="organisationUser | filterLoopPipe:inputParams.objectAddingOrRemovingIndividual[inputParams.attributeNameForIndividuals]:'userId'">{{organisationUser.email}}</option>
                    </ng-container>
                </ng-container>
            </select>
            <ng-container *ngIf="!isAddingIndividual">
                <button class="rounded-btn-base rounded-btn-light-outline full-width" (click)="isAddingIndividual = true">
                    <i class="fal fa-plus"></i> Add User
                </button>
            </ng-container>
        </ng-container>
    </div>
</div>
